import {AxiosError} from "axios";
import {axiosInstance} from "../axios/axiosInstance";
import {CREATE_LISTS, EXPORT_ITEMS, GET_ITEM_LIST, GET_LISTS, LIST_ADD_ITEM, LIST_RMV_ITEM, MOVE_ITEM} from "../../utils/constants";
import {TItemList, TListRequest} from "../../models/lists";
import {Notification} from "app/components/toastNotification/toastNotification";
import {IPage, IPageable, IResponseBody} from "../../models/utils";
import { removeNonNumeric } from "core/utils/globalFunctions";

// ! pegar listas prontas
const getLists = async (pageable: IPageable) => {
    return await axiosInstance
        .get(GET_LISTS, {
            params: {
                page: pageable.page,
                size: pageable.size,
                sort: pageable.sort,
            }
        })
        .then((resp) => {
            if (resp instanceof AxiosError) {
                console.log(AxiosError);
            }
            // console.log(resp.data.data.content)
            return resp.data.data;
        })
        .catch(console.error);
};

const removeItemList = async (id: number) => {
    return await axiosInstance
        .delete(`${LIST_RMV_ITEM}${id}`)
        .then((resp) => {
            if (resp instanceof AxiosError) {
                console.log(AxiosError);
            }
            // console.log(resp.data.data.content)
            Notification(resp.data.data, "success");
            return resp.data.data.content;
        })
        .catch((err: any) => {
            if (err.response?.data) {
                Notification(err.response?.data?.errors[0], "error");
            }
            else {
                Notification(err.message, "error");
            }
        });
};

const getItemList = async (id: number) => {
    return await axiosInstance
        .get(GET_ITEM_LIST, {params: {listId: id}})
        .then((resp) => {
            if (resp instanceof AxiosError) {
                console.log(AxiosError);
            }
            //console.log(resp.data.data.content);
            return resp.data.data.content;
        })
        .catch(console.error);
};

const getItemsCsv = async (
    itemsPageable: any
): Promise<any | undefined> => {
    const response = await axiosInstance.get(EXPORT_ITEMS, {
        params: {
            listId: itemsPageable.listId,
            clientCpforCnpj: removeNonNumeric(itemsPageable.clientCpforCnpj),
            nameClient: itemsPageable.nameClient,
        },
    });
    return response.data.data;
};

export interface IPageableItemsList extends IPageable {
    listId: number;
    clientCpforCnpj?: string;
    nameClient?: string;
}

const getFilteredItems = async (
    itemsPageable: IPageableItemsList
): Promise<IPage<TItemList> | undefined> => {
    const response = await axiosInstance.get<IResponseBody<IPage<TItemList>>>(
        GET_ITEM_LIST,
        {
            params: {
                page: itemsPageable.page,
                size: itemsPageable.size,
                sort: "createdAt,desc",
                listId: itemsPageable.listId,
                clientCpforCnpj: itemsPageable.clientCpforCnpj === "" ? undefined : removeNonNumeric(itemsPageable.clientCpforCnpj),  
                nameClient: itemsPageable.nameClient,
            },
        }
    );
    return response.data.data;
};

const addItemList = async (data: TListRequest) => {
    return await axiosInstance
        .post(LIST_ADD_ITEM, data)
        .then((resp) => {
            if (resp instanceof AxiosError) {
                console.log(AxiosError);
            }
            console.log(resp.data);
            Notification(resp.data.data, "success");
            return resp.data;
        })
        .catch((err: any) => {
            if (err.response?.data) {
                Notification(err.response?.data?.errors[0], "error");
            }
            else {
                Notification(err.message, "error");
            }
        });
};


const moveItemList = async (listId: string, itemListId: string) => {
    return await axiosInstance
        .post(MOVE_ITEM, {
            listId,
            itemListId,
        })
        .then((resp) => {
            if (resp instanceof AxiosError) {
                console.log(AxiosError);
            }
            console.log(resp.data);
            Notification(resp.data.data, "success");
            return resp.data;
        })
        .catch((err: any) => {
            if (err.response?.data) {
                Notification(err.response?.data?.errors[0], "error");
            }
            else {
                Notification(err.message, "error");
            }
        });
}

const approveList = async () => {
    return await axiosInstance
        .post(CREATE_LISTS)
        .then((resp) => {
            if (resp instanceof AxiosError) {
                console.log(AxiosError);
            }
            console.log(resp.data);
            Notification(resp.data.data, "success");
            return resp.data;
        })
        .catch((err: any) => {
            if (err.response?.data) {
                Notification(err.response?.data?.errors[0], "error");
            }
            else {
                Notification(err.message, "error");
            }
        });
};

export const ListsService = {
    approveList,
    getLists,
    getItemList,
    addItemList,
    removeItemList,
    getFilteredItems,
    getItemsCsv,
    moveItemList
};
