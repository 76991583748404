import styled from "styled-components";
import {
  Box,
  Button,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import HailIcon from "@mui/icons-material/Hail";

import GenericModal from "app/components/modals/genericModal";
import BoletoInfo from "app/components/modals/sales/salesInfo/boleto";
import PixInfo from "app/components/modals/sales/salesInfo/pix";
import { TBoletoBodyResponse } from "core/models/payment/boleto";
import { TPixBodyResponse } from "core/models/payment/pix";
import { TPayment, TSaleBody } from "core/models/sale";
import theme from "core/theme/theme";
import { useState } from "react";
import DefaultModal from "app/components/modals/defaultModal/defaultModal";
import { ClientClearNameDeal } from "app/views/newSale/content/deal";
import { useQuery } from "@tanstack/react-query";
import { fetchClientDeal } from "core/querryes/sale/getCientDealQuerry";
import { getUserFromToken } from "core/redux/slices/authSlice";
import DefaultDialog from "app/components/defaultDialog/defaultDialog";
import { SaleService } from "core/api/sale/saleService";
import { COUPON } from "core/utils/constants";
import { TCouponResponse } from "core/models/coupons";
import AsyncAutoComplete from "app/components/assyncAutoComplete/asyncAutoComplete";
import { CouponService } from "core/api/coupons/couponService";
import AlterSellerModal from "app/components/modals/sales/alterSeller/alterSellerModal";
import {
  formatCep2,
  formatDocument,
  formatMoney,
  formatPhoneNumber,
} from "core/utils/globalFunctions";

/*
    Detalhes de uma venda
    Todos os processos relacionados a venda estão aqui
    Recebe a venda como prop
*/

interface SaleDetailModalProps {
  sale: any;
  isOpen: boolean;
  onClose: () => void;
  reRender: () => void;
}

export default function SaleDetailModal({
  isOpen,
  onClose,
  sale,
  reRender,
}: Readonly<SaleDetailModalProps>) {
  const userInfo = getUserFromToken();

  const [isLoading, setIsLoading] = useState(false);
  const [processingRequest, setProcessingRequest] = useState(false);
  const [confirmChange, setConfirmChange] = useState(false);
  const [viewContract, setViewContract] = useState(false);
  const [addCouponModal, setAddCouponModal] = useState(false);
  const [alterSellerModal, setAlterSellerModal] = useState(false);

  const [idCouponState, setIdCoupon] = useState<number | undefined>();
  const [valuePixCpf, setValuePixCpf] = useState<number | undefined>();
  const [valuePixCnpj, setValuePixCnpj] = useState<number | undefined>();
  const [valueInstallmentCpf, setValueInstallmentCpf] = useState<
    number | undefined
  >();
  const [valueInstallmentCnpj, setValueInstallmentCnpjn] = useState<
    number | undefined
  >();

  const dealQuerry = useQuery({
    queryKey: ["deal", sale?.client?.id],
    queryFn: () => fetchClientDeal(sale?.client?.id),
  });

  function salePrice(
    saleType: string,
    documentType: string,
    paymentType: string,
    coupon?: { pixValue?: number; installmentValue?: number }
  ): number {
    let price: number;
    if (saleType === "CONSULTORIA") {
      price = 297;
    } else {
      const prices: any = {
        PIX: {
          CPF: 1500,
          CNPJ: 2000,
        },
        BOLETO: {
          CPF: 800,
          CNPJ: 1050,
        },
      };
      price = prices[paymentType]?.[documentType] || 0;
    }
    if (coupon?.installmentValue && paymentType === "BOLETO") {
      price = coupon?.installmentValue;
    }
    if (coupon?.pixValue && paymentType === "PIX") {
      price = coupon?.pixValue;
    }
    return price;
  }

  const changeSaleType = async (id: string, type: TPayment) => {
    setProcessingRequest(true);

    if (type === "PIX" || type === "BOLETO") {
      await SaleService.changeSalePaymentType({
        id,
        typePayment: type === "PIX" ? "BOLETO" : "PIX",
      });
      reRender();
      setProcessingRequest(false);
      setConfirmChange(false);
    }

    return setProcessingRequest(false);
  };

  const handleClearIdCoupon = () => {
    setIdCoupon(undefined);
    setValueInstallmentCnpjn(undefined);
    setValueInstallmentCpf(undefined);
    setValuePixCnpj(undefined);
    setValuePixCpf(undefined);
  };

  const handleSelectCoupon = (selected: TCouponResponse) => {
    console.log("cupom Selecionado: ", selected.id);
    setIdCoupon(Number(selected.id));
    setValuePixCpf(Number(selected.valuePixCpf));
    setValuePixCnpj(Number(selected.valuePixCnpj));
    setValueInstallmentCpf(Number(selected.valueInstallmentCpf));
    setValueInstallmentCnpjn(Number(selected.valueInstallmentCnpj));
  };

  const handleConfirmCoupon = async (idSale: number) => {
    setIsLoading(true);
    if (idCouponState) {
      await CouponService.addCoupon(idSale, idCouponState);
      reRender();
      setAddCouponModal(false);
      handleClearIdCoupon();
    }
    setIsLoading(false);
  };

  const isSmallScreen = useMediaQuery("(max-width:900px)");

  return (
    <GenericModal
      title="Detalhes da venda"
      onClose={onClose}
      open={isOpen}
      width={isSmallScreen ? "100%" : "1000px"}
      height={
        isSmallScreen
          ? "100svh"
          : sale.coupon || sale.indication
          ? "550px"
          : "400px"
      }
    >
      <ModalContent>
        {!isSmallScreen ? (
          <Box
            sx={{
              display: "flex",
            }}
          >
            <LeftSection>
              {/* Cliente */}
              <Container>
                <Title>Cliente</Title>
                <Column>
                  <Row>
                    <Label>Nome:</Label>
                    <Value>{sale.client.name}</Value>
                  </Row>
                  <Row>
                    <Label>CPF/CNPJ:</Label>
                    <Value>{formatDocument(sale.client.cpforcnpj)}</Value>
                  </Row>
                  <Row>
                    <Label>Email:</Label>
                    <Value>{sale.client.email}</Value>
                  </Row>
                  <Row>
                    <Label>Contato:</Label>
                    <Value>{formatPhoneNumber(sale.client.telephone)}</Value>
                  </Row>
                  <Row>
                    <Label>Cep:</Label>
                    <Value>{formatCep2(sale.client.cep)}</Value>
                  </Row>
                  <Row>
                    <Label>Bairro:</Label>
                    <Value>{sale.client.neighborhood}</Value>
                  </Row>
                  <Row>
                    <Label>Estado:</Label>
                    <Value>{sale.client.uf}</Value>
                  </Row>
                </Column>
              </Container>

              {/* Vendedor caso tenha cupom ou indicação */}
              {(sale.coupon || sale.indication) && (
                <Container>
                  <Title>Vendedor</Title>
                  <Column>
                    <Row>
                      <Label>Nome:</Label>
                      <Value>{sale.seller.name}</Value>
                    </Row>
                    <Row>
                      <Label>CPF/CNPJ:</Label>
                      <Value>{formatDocument(sale.seller.cpforcnpj)}</Value>
                    </Row>
                    <Row>
                      <Label>Email:</Label>
                      <Value>{sale.seller.email}</Value>
                    </Row>
                  </Column>
                </Container>
              )}
            </LeftSection>
            {/* Lado direito */}

            <RightSection>
              {/* Vendedor */}
              {!sale.coupon && !sale.indication && (
                <Container>
                  <Title>Vendedor</Title>
                  <Column>
                    <Row>
                      <Label>Nome:</Label>
                      <Value>{sale.seller.name}</Value>
                    </Row>
                    <Row>
                      <Label>CPF/CNPJ:</Label>
                      <Value>{formatDocument(sale.seller.cpforcnpj)}</Value>
                    </Row>
                    <Row>
                      <Label>Email:</Label>
                      <Value>{sale.seller.email}</Value>
                    </Row>
                  </Column>
                </Container>
              )}
              {/* Indicação e cupom se tiver */}
              {sale?.indication && (
                <Container>
                  <Title>Indicação</Title>
                  <Column>
                    <Row>
                      <Label>Nome:</Label>
                      <Value>{sale.indication?.coupon ?? ""}</Value>
                    </Row>
                    <Row>
                      <Label>CPF/CNPJ:</Label>
                      <Value>
                        {formatDocument(sale.indication.cpforcnpj) ?? ""}
                      </Value>
                    </Row>
                  </Column>
                </Container>
              )}
              {sale?.coupon && (
                <Container>
                  <Title>Cupom</Title>
                  <Column>
                    <Row>
                      <Label>Nome:</Label>
                      <Value>{sale.coupon.coupon ?? ""}</Value>
                    </Row>
                    <Row>
                      <Label>Descrição:</Label>
                      <Value>{sale.coupon.description ?? ""}</Value>
                    </Row>
                    {sale?.typePayment === "PIX" ? (
                      (sale?.client.cpforcnpj).length === 11 ? (
                        <Row>
                          <Label>Valor pix CPF:</Label>
                          <Value>
                            {formatMoney(sale?.coupon?.valuePixCpf)}
                          </Value>
                        </Row>
                      ) : (
                        <Row>
                          <Label>Valor pix CNPJ:</Label>
                          <Value>
                            {formatMoney(sale?.coupon?.valuePixCnpj)}
                          </Value>
                        </Row>
                      )
                    ) : (
                      <>
                        <Row>
                          <Label>Quantidades de parcelas:</Label>
                          <Value>{sale?.coupon?.quantityInstallments}</Value>
                        </Row>
                        {(sale?.client.cpforcnpj).length === 11 ? (
                          <Row>
                            <Label>Valor em parcelas CPF:</Label>
                            <Value>
                              {formatMoney(sale?.coupon?.valueInstallmentCpf)}
                            </Value>
                          </Row>
                        ) : (
                          <Row>
                            <Label>Valor em parcelas CNPJ:</Label>
                            <Value>
                              {formatMoney(sale?.coupon?.valueInstallmentCnpj)}
                            </Value>
                          </Row>
                        )}
                      </>
                    )}
                  </Column>
                </Container>
              )}

              {(sale.coupon || sale.indication) && <Box sx={{ flex: 1 }}></Box>}

              {/* Ações do usuário sobre a venda */}
              <SaleActions>
                <Typography color={"#fff"} fontWeight={"bold"}>
                  Ações:
                </Typography>
                <Tooltip title="Mudar método de pagamento">
                  <IconButton
                    sx={{
                      background: "#fff",
                      borderRadius: "10px",
                      ":hover": {
                        color: theme.COLORS.BLUE3,
                        background: theme.COLORS.GRAY5,
                      },
                      ":disabled": {
                        background: theme.COLORS.GRAY4,
                      },
                    }}
                    disabled={
                      processingRequest ||
                      sale.status === "EM_PAGAMENTO" ||
                      sale.status === "PAGO" ||
                      sale.typeSales === "CONSULTORIA"
                    }
                    onClick={() => setConfirmChange(true)}
                  >
                    <SwapHorizIcon
                      sx={{
                        color:
                          sale.status === "EM_PAGAMENTO" ||
                          sale.status === "PAGO" ||
                          sale.typeSales === "CONSULTORIA"
                            ? theme.COLORS.WHITE
                            : theme.COLORS.BLUE3,
                      }}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Contrato desta venda">
                  <IconButton
                    sx={{
                      background: "#fff",
                      borderRadius: "10px",
                      ":hover": {
                        color: theme.COLORS.BLUE3,
                        background: theme.COLORS.GRAY5,
                      },
                    }}
                    onClick={() => setViewContract(true)}
                  >
                    <ReceiptLongIcon sx={{ color: theme.COLORS.BLUE3 }} />
                  </IconButton>
                </Tooltip>
                {userInfo?.group === "ADMIN" && (
                  <>
                    <Tooltip title="Adicionar cupom a venda">
                      <IconButton
                        sx={{
                          background: "#fff",
                          borderRadius: "10px",
                          ":hover": {
                            color: theme.COLORS.BLUE3,
                            background: theme.COLORS.GRAY5,
                          },
                          ":disabled": {
                            background: theme.COLORS.GRAY4,
                          },
                        }}
                        onClick={() => setAddCouponModal(true)}
                        disabled={
                          sale.status === "EM_PAGAMENTO" ||
                          sale.status === "PAGO" ||
                          sale.typeSales === "CONSULTORIA"
                        }
                      >
                        <BookmarkAddIcon
                          sx={{
                            color:
                              sale.status === "EM_PAGAMENTO" ||
                              sale.status === "PAGO" ||
                              sale.typeSales === "CONSULTORIA"
                                ? theme.COLORS.WHITE
                                : theme.COLORS.BLUE3,
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Trocar vendedor">
                      <IconButton
                        sx={{
                          background: "#fff",
                          borderRadius: "10px",
                          ":hover": {
                            color: theme.COLORS.BLUE3,
                            background: theme.COLORS.GRAY5,
                          },
                          ":disabled": {
                            background: theme.COLORS.GRAY4,
                          },
                        }}
                        onClick={() => setAlterSellerModal(true)}
                      >
                        <HailIcon sx={{ color: theme.COLORS.BLUE3 }} />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </SaleActions>
            </RightSection>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* Cliente */}
            <Container>
              <Title>Cliente</Title>
              <Column>
                <Row>
                  <Label>Nome:</Label>
                  <Value>{sale.client.name}</Value>
                </Row>
                <Row>
                  <Label>CPF/CNPJ:</Label>
                  <Value>{formatDocument(sale.client.cpforcnpj)}</Value>
                </Row>
                <Row>
                  <Label>Email:</Label>
                  <Value>{sale.client.email}</Value>
                </Row>
                <Row>
                  <Label>Contato:</Label>
                  <Value>{formatPhoneNumber(sale.client.telephone)}</Value>
                </Row>
                <Row>
                  <Label>Cep:</Label>
                  <Value>{formatCep2(sale.client.cep)}</Value>
                </Row>
                <Row>
                  <Label>Bairro:</Label>
                  <Value>{sale.client.neighborhood}</Value>
                </Row>
                <Row>
                  <Label>Estado:</Label>
                  <Value>{sale.client.uf}</Value>
                </Row>
              </Column>
            </Container>
            {/* Vendedor caso tenha cupom ou indicação */}
            {(sale.coupon || sale.indication) && (
              <Container>
                <Title>Vendedor</Title>
                <Column>
                  <Row>
                    <Label>Nome:</Label>
                    <Value>{sale.seller.name}</Value>
                  </Row>
                  <Row>
                    <Label>CPF/CNPJ:</Label>
                    <Value>{formatDocument(sale.seller.cpforcnpj)}</Value>
                  </Row>
                  <Row>
                    <Label>Email:</Label>
                    <Value>{sale.seller.email}</Value>
                  </Row>
                </Column>
              </Container>
            )}
            {/* Vendedor */}
            {!sale.coupon && !sale.indication && (
              <Container>
                <Title>Vendedor</Title>
                <Column>
                  <Row>
                    <Label>Nome:</Label>
                    <Value>{sale.seller.name}</Value>
                  </Row>
                  <Row>
                    <Label>CPF/CNPJ:</Label>
                    <Value>{formatDocument(sale.seller.cpforcnpj)}</Value>
                  </Row>
                  <Row>
                    <Label>Email:</Label>
                    <Value>{sale.seller.email}</Value>
                  </Row>
                </Column>
              </Container>
            )}
            {/* Indicação e cupom se tiver */}
            {sale?.indication && (
              <Container>
                <Title>Indicação</Title>
                <Column>
                  <Row>
                    <Label>Nome:</Label>
                    <Value>{sale.indication?.coupon ?? ""}</Value>
                  </Row>
                  <Row>
                    <Label>CPF/CNPJ:</Label>
                    <Value>
                      {formatDocument(sale.indication.cpforcnpj) ?? ""}
                    </Value>
                  </Row>
                </Column>
              </Container>
            )}
            {sale?.coupon && (
              <Container>
                <Title>Cupom</Title>
                <Column>
                  <Row>
                    <Label>Nome:</Label>
                    <Value>{sale.coupon.coupon ?? ""}</Value>
                  </Row>
                  <Row>
                    <Label>Descrição:</Label>
                    <Value>{sale.coupon.description ?? ""}</Value>
                  </Row>
                  {sale?.typePayment === "PIX" ? (
                    (sale?.client.cpforcnpj).length === 11 ? (
                      <Row>
                        <Label>Valor pix CPF:</Label>
                        <Value>{formatMoney(sale?.coupon?.valuePixCpf)}</Value>
                      </Row>
                    ) : (
                      <Row>
                        <Label>Valor pix CNPJ:</Label>
                        <Value>{formatMoney(sale?.coupon?.valuePixCnpj)}</Value>
                      </Row>
                    )
                  ) : (
                    <>
                      <Row>
                        <Label>Quantidades de parcelas:</Label>
                        <Value>{sale?.coupon?.quantityInstallments}</Value>
                      </Row>
                      {(sale?.client.cpforcnpj).length === 11 ? (
                        <Row>
                          <Label>Valor em parcelas CPF:</Label>
                          <Value>
                            {formatMoney(sale?.coupon?.valueInstallmentCpf)}
                          </Value>
                        </Row>
                      ) : (
                        <Row>
                          <Label>Valor em parcelas CNPJ:</Label>
                          <Value>
                            {formatMoney(sale?.coupon?.valueInstallmentCnpj)}
                          </Value>
                        </Row>
                      )}
                    </>
                  )}
                </Column>
              </Container>
            )}
            {(sale.coupon || sale.indication) && <Box sx={{ flex: 1 }}></Box>}
            {/* Ações do usuário sobre a venda */}
            <SaleActions>
              <Typography color={"#fff"} fontWeight={"bold"}>
                Ações:
              </Typography>
              <Tooltip title="Mudar método de pagamento">
                <IconButton
                  sx={{
                    background: "#fff",
                    borderRadius: "10px",
                    ":hover": {
                      color: theme.COLORS.BLUE3,
                      background: theme.COLORS.GRAY5,
                    },
                    ":disabled": {
                      background: theme.COLORS.GRAY4,
                    },
                  }}
                  disabled={
                    processingRequest ||
                    sale.status === "EM_PAGAMENTO" ||
                    sale.status === "PAGO" ||
                    sale.typeSales === "CONSULTORIA"
                  }
                  onClick={() => setConfirmChange(true)}
                >
                  <SwapHorizIcon
                    sx={{
                      color:
                        sale.status === "EM_PAGAMENTO" ||
                        sale.status === "PAGO" ||
                        sale.typeSales === "CONSULTORIA"
                          ? theme.COLORS.WHITE
                          : theme.COLORS.BLUE3,
                    }}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="Contrato desta venda">
                <IconButton
                  sx={{
                    background: "#fff",
                    borderRadius: "10px",
                    ":hover": {
                      color: theme.COLORS.BLUE3,
                      background: theme.COLORS.GRAY5,
                    },
                  }}
                  onClick={() => setViewContract(true)}
                >
                  <ReceiptLongIcon sx={{ color: theme.COLORS.BLUE3 }} />
                </IconButton>
              </Tooltip>
              {userInfo?.group === "ADMIN" && (
                <>
                  <Tooltip title="Adicionar cupom a venda">
                    <IconButton
                      sx={{
                        background: "#fff",
                        borderRadius: "10px",
                        ":hover": {
                          color: theme.COLORS.BLUE3,
                          background: theme.COLORS.GRAY5,
                        },
                        ":disabled": {
                          background: theme.COLORS.GRAY4,
                        },
                      }}
                      onClick={() => setAddCouponModal(true)}
                      disabled={
                        sale.status === "EM_PAGAMENTO" ||
                        sale.status === "PAGO" ||
                        sale.typeSales === "CONSULTORIA"
                      }
                    >
                      <BookmarkAddIcon
                        sx={{
                          color:
                            sale.status === "EM_PAGAMENTO" ||
                            sale.status === "PAGO" ||
                            sale.typeSales === "CONSULTORIA"
                              ? theme.COLORS.WHITE
                              : theme.COLORS.BLUE3,
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Trocar vendedor">
                    <IconButton
                      sx={{
                        background: "#fff",
                        borderRadius: "10px",
                        ":hover": {
                          color: theme.COLORS.BLUE3,
                          background: theme.COLORS.GRAY5,
                        },
                        ":disabled": {
                          background: theme.COLORS.GRAY4,
                        },
                      }}
                      onClick={() => setAlterSellerModal(true)}
                    >
                      <HailIcon sx={{ color: theme.COLORS.BLUE3 }} />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </SaleActions>
          </Box>
        )}
        {/* Pagamentos */}
        <ScrollableSection>
          {sale.paymentMethods.map((payment: any) => {
            if (payment.type === "PIX") {
              return (
                <PixInfo
                  key={payment.id}
                  idSale={sale.id}
                  saleStatus={sale.status}
                  reRender={reRender}
                  pix={payment as TPixBodyResponse}
                  idPayment={payment.id}
                />
              );
            } else {
              return (
                <BoletoInfo
                  saleStatus={sale.status}
                  boleto={payment as TBoletoBodyResponse}
                  client={sale.client}
                  reRender={reRender}
                  key={payment.id}
                />
              );
            }
          })}
        </ScrollableSection>
      </ModalContent>

      {/* Modal de contrato */}
      <DefaultModal
        title="Contrato da venda"
        isOpen={viewContract}
        onClose={() => setViewContract(false)}
        onOpen={() => setViewContract(true)}
      >
        <Box
          sx={{
            padding: 2,
            width: isSmallScreen ? 500 : 700,
            height: isSmallScreen ? 300 : 400,
            overflowY: "scroll",
            background: theme.COLORS.GRAY7,
          }}
        >
          {!dealQuerry.isLoading && (
            <ClientClearNameDeal
              clientAddressAsString={`${sale?.client.address}, ${sale?.client.neighborhood}, ${sale?.client.city}, ${sale?.client.uf}, ${sale?.client.cep}`}
              clientDocument={sale?.client.cpforcnpj}
              clientName={sale?.client.name}
              fullValue={salePrice(
                dealQuerry.data[0].etypeSales,
                dealQuerry.data[0].typeClient,
                "PIX",
                dealQuerry.data[0].couponDTO
              )}
              halfValue={salePrice(
                dealQuerry.data[0].etypeSales,
                dealQuerry.data[0].typeClient,
                "BOLETO",
                dealQuerry.data[0].couponDTO
              )}
              dowloadFunction={true}
              createDate={dealQuerry.data[0].dateSale}
            />
          )}
        </Box>
      </DefaultModal>

      {/* Modal de alteração de vendedor */}
      <AlterSellerModal
        sale={sale}
        isOpen={alterSellerModal}
        onClose={() => setAlterSellerModal(false)}
        onOpen={() => setAlterSellerModal(true)}
        reRender={reRender}
      />

      {/* Modal de adicionar cupom */}
      <DefaultModal
        title="Adicionar cupom a venda"
        isOpen={addCouponModal}
        onClose={() => {
          setAddCouponModal(false);
          handleClearIdCoupon();
        }}
        onOpen={() => setAddCouponModal(true)}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 1,
            gap: 2,
          }}
        >
          <AsyncAutoComplete
            style={{ width: 200 }}
            label="Procurar um cupom"
            urlToSearch={COUPON}
            sortField="createdAt"
            handleOnSelect={handleSelectCoupon}
            onClear={handleClearIdCoupon}
            searchParamName="nameCoupon"
          />
          {
            <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <Typography>Valor em pix Cpf: {valuePixCpf ?? ""}</Typography>
                <Typography>Valor em pix Cnpj: {valuePixCnpj ?? ""}</Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <Typography>
                  Valor em boleto Cpf: {valueInstallmentCpf ?? ""}
                </Typography>
                <Typography>
                  Valor em boleto Cnpj: {valueInstallmentCnpj ?? ""}
                </Typography>
              </Box>
            </Box>
          }
          <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
            <Button
              onClick={() => {
                handleClearIdCoupon();
                setAddCouponModal(false);
              }}
            >
              CANCELAR
            </Button>
            <Button
              disabled={!idCouponState || isLoading}
              onClick={() => handleConfirmCoupon(Number(sale.id))}
            >
              CONFIRMAR
            </Button>
          </Box>
        </Box>
      </DefaultModal>

      {/* Confirmação de alteração de pagamento */}
      <DefaultDialog
        title="Confirmação"
        isOpen={confirmChange}
        confirmAction={() => changeSaleType(sale.id, sale.typePayment)}
        onCloseAction={() => setConfirmChange(false)}
        body={
          <Box sx={{ width: 200 }}>
            <Typography textAlign={"start"}>
              Deseja realmente mudar a forma de pagamento desta compra?
            </Typography>
            <Typography textAlign={"center"}>
              Método atual:{" "}
              <span style={{ fontWeight: "bold" }}>{sale.typePayment}</span>
            </Typography>
          </Box>
        }
      />
    </GenericModal>
  );
}

const SaleActions = styled.div`
  width: 100%;
  height: 70px;
  background: ${theme.COLORS.BLUE3};
  margin: 10px;
  padding: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
  gap: 10px;
  justify-content: start;
  align-items: center;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const LeftSection = styled.div`
  flex: 1;
`;

const RightSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ScrollableSection = styled.div`
  border-top: 2px solid ${theme.COLORS.GRAY5};
  padding: 10px;
  height: 140px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: min-content;
  padding: 10px;
`;

const Title = styled(Typography)`
  font-weight: bold !important;
  font-size: 1.2pc !important;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Label = styled(Typography)`
  font-weight: normal !important;
`;

const Value = styled(Typography)`
  font-weight: normal !important;
`;
