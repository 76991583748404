// noinspection UnnecessaryLocalVariableJS
import { BACKEND_BASE_URL } from "./constants";
import { Dayjs } from "dayjs";

export function FormatFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export const getDefaultYearDates = () => {
  const now = new Date();
  const yearStart = new Date(now.getFullYear(), 0, 1)
    .toISOString()
    .split("T")[0];
  const yearEnd = new Date(now.getFullYear(), 11, 31)
    .toISOString()
    .split("T")[0];
  return { yearStart, yearEnd };
};

export const getCurrentMonthDates = () => {
  const now = new Date();
  const firstDay = new Date(now.getFullYear(), now.getMonth(), 1)
    .toISOString()
    .split("T")[0];
  const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0)
    .toISOString()
    .split("T")[0];
  return { startDate: firstDay, endDate: lastDay };
};

export function formatDocument(doc: string) {
  doc = doc.replace(/\D/g, "");
  if (doc.length === 11) {
    doc = doc.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  } else if (doc.length === 14) {
    doc = doc.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
  }
  return doc;
}

export function formatDocument2(doc: string | undefined) {
  if (!doc) return null;
  doc = doc.replace(/\D/g, "");
  if (doc.length === 11) {
    doc = doc.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  } else if (doc.length === 14) {
    doc = doc.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
  }
  return doc;
}

export function formatPhoneNumber(phoneNumber: string) {
  let numericPhoneNumber = phoneNumber.replace(/[^0-9]/g, "");
  if (numericPhoneNumber.length === 11) {
    numericPhoneNumber = numericPhoneNumber.replace(
      /^(\d{2})(\d{1})(\d{4})(\d{4})$/,
      (_, ddd, nine, firstPart, secondPart) =>
        `(${ddd}) ${nine}${firstPart}-${secondPart}`
    );
  } else if (numericPhoneNumber.length === 13) {
    numericPhoneNumber = numericPhoneNumber.replace(
      /^(\d{2})(\d{2})(\d{1})(\d{4})(\d{4})$/,
      (_, country, ddd, nine, firstPart, secondPart) =>
        `+${country} (${ddd}) ${nine} ${firstPart}-${secondPart}`
    );
  }
  return numericPhoneNumber;
}

export function formatCEP(cep: string) {
  return cep.replace(/^(\d{2})(\d{3})(\d{2})$/, "$1.$2-$3");
}

export function formatCep2(cep: string): string {
  // Remove todos os caracteres não numéricos
  const numericCep = cep.replace(/\D/g, "");

  // Aplica a formatação apenas se tiver 8 dígitos
  return numericCep.length === 8
    ? `${numericCep.slice(0, 2)}.${numericCep.slice(2, 5)}-${numericCep.slice(
        5
      )}`
    : "--";
}

export function formatDateBr(dataISO: string): string {
  if (!dataISO) {
    return "--";
  }

  const [ano, mes, dia] = dataISO.split("T")[0].split("-");

  return `${dia}/${mes}/${ano}`;
}

export function formatDateBrWithHours(dataISO: string): string {
  if (!dataISO) {
    return "--";
  }

  const [ano, mes, dia] = dataISO.split("T")[0].split("-");
  const [hora, minutos] = dataISO.split("T")[1].split(":");

  return `${dia}/${mes}/${ano} - ${hora}:${minutos}`;
}

export function formatarData(data: string): string {
  const date = new Date(data);

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Meses começam do 0
  const year = date.getFullYear();

  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
}

export function formatarDataSemHoras(data: string): string {
  const dataObj = new Date(data);

  // Extrai apenas o dia, mês e ano
  const dia = String(dataObj.getUTCDate()).padStart(2, "0");
  const mes = String(dataObj.getUTCMonth() + 1).padStart(2, "0");
  const ano = dataObj.getUTCFullYear();

  return `${dia}/${mes}/${ano}`;
}

function convertTypeToString(type: string): string {
  switch (type) {
    case "SELLER": {
      return "seller";
    }
    case "FINANCEIRO": {
      return "seller";
    }
    case "CLIENT": {
      return "client";
    }
    case "ADMIN": {
      return "seller";
    }
    case "INDICATION": {
      return "indication";
    }
    default: {
      return "";
    }
  }
}

export function urlByUserType(type: string, id: number, put?: boolean): string {
  let userUrl = `${BACKEND_BASE_URL}v1/${convertTypeToString(type)}/${
    put ? "update/" : ""
  }${id}`;
  return userUrl;
}

export function capitalizeFirstLetter(input: string): string {
  if (!input) return input; // Verifica se a string não está vazia

  // Substitui todos os sublinhados por espaços
  const formattedInput = input.replace(/_/g, " ");

  // Capitaliza a primeira letra e transforma o restante em minúsculas
  return (
    formattedInput.charAt(0).toUpperCase() +
    formattedInput.slice(1).toLowerCase()
  );
}

export function validarCpfCnpj(input: string): boolean {
  const cleanedInput = input.replace(/\D/g, "");
  if (cleanedInput.length === 11) {
    return validarCpf(cleanedInput);
  } else if (cleanedInput.length === 14) {
    return validarCnpj(cleanedInput);
  }
  return false;
}

function validarCpf(cpf: string): boolean {
  const numeros = cpf.substring(0, 9).split("").map(Number);
  const digitos = cpf.substring(9).split("").map(Number);

  const calcularDigito = (numeros: number[]): number => {
    let soma = 0;
    let multiplicador = numeros.length + 1;

    for (const numero of numeros) {
      soma += numero * multiplicador;
      multiplicador--;
    }

    const resto = soma % 11;
    return resto < 2 ? 0 : 11 - resto;
  };

  const primeiroDigito = calcularDigito(numeros);
  const segundoDigito = calcularDigito([...numeros, primeiroDigito]);

  return digitos[0] === primeiroDigito && digitos[1] === segundoDigito;
}

function validarCnpj(cnpj: string): boolean {
  const numeros = cnpj.substring(0, 12).split("").map(Number);
  const digitos = cnpj.substring(12).split("").map(Number);

  const calcularDigito = (numeros: number[], multiplicador: number): number => {
    let soma = 0;

    for (const numero of numeros) {
      soma += numero * multiplicador;
      multiplicador = multiplicador === 2 ? 9 : multiplicador - 1;
    }

    const resto = soma % 11;
    return resto < 2 ? 0 : 11 - resto;
  };

  const primeiroDigito = calcularDigito(numeros, 5);
  const segundoDigito = calcularDigito([...numeros, primeiroDigito], 6);

  return digitos[0] === primeiroDigito && digitos[1] === segundoDigito;
}

export function formatCurrencyBR(input: number | undefined): string {
  if (input === undefined) {
    return "R$ 0,00";
  }

  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(input);
}

export function downloadCSVFromBase64(
  base64String: string,
  fileName: string
): void {
  // Convertendo a string base64 para um array de bytes
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);

  // Criando um Blob a partir do array de bytes
  const blob = new Blob([byteArray], { type: "text/csv" });

  // Criando um link temporário para download
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;

  // Simulando o clique no link para iniciar o download
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function removeExtraSpaces(input: string): string {
  return input.trim();
}

export function removeNonNumeric(
  input: string | undefined
): string | undefined {
  if (input) return input.replace(/\D/g, "");
  else return undefined;
}

export function formatCurrency(numero?: number): string {
  if (numero === undefined || numero === null) {
    return "0,00";
  }

  // Formatando o número com duas casas decimais
  const numeroFormatado = numero.toFixed(2);

  // Substituindo o ponto por vírgula
  return numeroFormatado.replace(".", ",");
}

export function periodoAtual(tipo: "mes" | "ano"): string {
  const dataAtual = new Date();
  const mesAtual = dataAtual.toLocaleString("pt-BR", { month: "long" });
  const anoAtual = dataAtual.getFullYear();

  if (tipo === "mes") {
    return `Período: ${mesAtual} ${anoAtual}`;
  } else if (tipo === "ano") {
    return `Período: ${anoAtual}`;
  } else {
    throw new Error(
      'Tipo de período inválido. Por favor, passe "mes" ou "ano" como parâmetro.'
    );
  }
}

export function formatInt(value: string): string {
  let numericValue = value.replace(/\D/g, "");
  return numericValue;
}

export function downloadPdf(base64String: string, fileName: string) {
  // Decodifica a string base64 para um array de bytes
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);

  // Cria um blob a partir do array de bytes
  const blob = new Blob([byteArray], { type: "application/pdf" });

  // Cria um URL de objeto para o blob
  const url = window.URL.createObjectURL(blob);

  // Cria um link (a) e atribui o URL do blob
  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;

  // Dispara o evento de clique no link para iniciar o download
  link.click();

  // Limpa o URL de objeto para liberar recursos
  window.URL.revokeObjectURL(url);
}

export function formatMoney(valor?: string) {
  if (!valor) {
    return "R$ 0,00";
  }

  const numero = parseFloat(valor).toFixed(2);
  const [parteInteira, parteDecimal] = numero.split(".");
  const parteInteiraFormatada = parteInteira.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    "."
  );

  return `R$ ${parteInteiraFormatada},${parteDecimal}`;
}

export function formatDateToChartReq(dateString: Dayjs): string {
  return String(dateString.format("YYYY-MM-DD"));
}

export function getMonthNameByIndex(index: number): string {
  const months = [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ];
  if (index < 0 || index > 11) {
    throw new Error("Índice deve estar entre 0 e 11.");
  }
  return months[index];
}

export function maxNameSize(name: string | undefined, maxLength: number) {
  if (!name) {
    return null;
  }
  if (name.length > maxLength) {
    return name.substring(0, maxLength) + "...";
  }
  return name;
}
