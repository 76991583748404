import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { TCourse } from 'core/models/course';
import { ContentHeader, ContentTitle, PageContentContainer } from 'app/components/styles';
import { ContentBody } from '../sellerList/styles';
import { ContainerVideos } from './styles';
import theme from 'core/theme/theme';
import { capitalizeFirstLetter } from 'core/utils/globalFunctions';
import ReactPlayer from 'react-player';

const CoursesVideos: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { course } = location.state as { course: TCourse } || {};
  const [loading, setLoading] = useState<boolean[]>([]);

  useEffect(() => {
    if (!course) {
      navigate(-1);
    } else {
      setLoading(new Array(course.videos.length).fill(true));
    }
  }, [course, navigate]);

  const handleVideoReady = (index: number) => {
    setLoading(prevLoading => {
      const newLoading = [...prevLoading];
      newLoading[index] = false;
      return newLoading;
    });
  };

  const renderYouTubeVideo = (videoUrl: string, description: string, index: number) => {
    return (
      <ContainerVideos>
        <Box sx={{ display: 'flex', borderRadius: '8px', flexDirection: 'column', height: "250px", width: "300px", background: theme.COLORS.GRAY5 }}>
          <Box sx={{
            display: 'flex',
            borderRadius: '8px',
            overflow: 'hidden',
            position: 'relative',
            margin: '10px',
            flex: 4,
            background: theme.COLORS.GRAY,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            {loading[index] && (
              <CircularProgress sx={{color: "#fff"}}/>
            )}
            <ReactPlayer
              url={videoUrl}
              controls={true}
              width="100%"
              height="100%"
              style={{ position: loading[index] ? 'absolute' : 'relative', top: 0, left: 0 }}
              onReady={() => handleVideoReady(index)}
            />
          </Box>
          <Box sx={{
            display: 'flex', borderBottom: `2px solid ${theme.COLORS.BLUE2}`,
            borderBottomLeftRadius: "7px", borderBottomRightRadius: "7px", justifyContent: 'center',
            alignItems: "center", flex: 1, width: "100%", height: "30px", background: theme.COLORS.BLUE2
          }}>
            <Typography sx={{ width: "100%", margin: "auto", fontWeight: "bold", color: theme.COLORS.WHITE }}>
              {capitalizeFirstLetter(description)}
            </Typography>
          </Box>
        </Box>
      </ContainerVideos>
    );
  };

  if (!course) {
    return <div>Curso não encontrado</div>;
  }

  return (
    <PageContentContainer>
      <ContentHeader>
        <ContentTitle>{capitalizeFirstLetter(course.description)}</ContentTitle>
      </ContentHeader>
      <ContentBody sx={{ alignItems: "center", display: "flex", paddingTop: "20px" }}>
        <Box sx={{ width: "90%" }}>
          <Grid container gap={2} justifyContent={"space-around"}>
            {course.videos.map((video, index) => (
              <Grid key={index} item>
                {renderYouTubeVideo(video.url, video.name, index)}
              </Grid>
            ))}
          </Grid>
        </Box>
      </ContentBody>
    </PageContentContainer>
  );
};

export default CoursesVideos;
