import { useSaleFormContext } from "core/context/SaleContext";
import { useCallback } from "react";

const useValidateAddressInfos = () => {
  const { formik, setActiveStep, activeStep } = useSaleFormContext();

  const validateAddressInfos = useCallback(async (): Promise<boolean> => {
    const isValid = await formik.validateForm().then((resp) => {
      if (Object.keys(resp).length === 0) {
        return true;
      }
      return false;
    });

    if (isValid) {
      setActiveStep(activeStep + 1);
      return true;
    }

    return false;
  }, [formik, setActiveStep, activeStep]);

  return { validateAddressInfos };
};

export default useValidateAddressInfos;
