import { Outlet } from "react-router-dom";
import { Navigate } from "react-router-dom";

import { getUserFromToken } from "core/redux/slices/authSlice";
import { useEffect } from "react";

const DefaultRoute = () => {
  const basicUserInfo = getUserFromToken();


  if (localStorage.getItem("token") !== null && basicUserInfo?.group === "CLIENT") {
    return <Navigate replace to={"/aulas"} />;
  }
  else if (localStorage.getItem("token") !== null) {
    return <Navigate replace to={"/dashboard"} />;
  }

  return (
    <>
      <Outlet />
    </>
  );
};

export default DefaultRoute;
