import React, { useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import { IPropsDataTable, ITableHeadCell } from "../../../../core/models/table";
import StyledTableHead from "../tableHead/tableHead";
import {
  formatCurrency,
  formatDateBr,
  formatDocument,
} from "core/utils/globalFunctions";
import theme from "theme";
import themeTwo from "core/theme/theme";
import { FiberManualRecord } from "@mui/icons-material";

interface ITableRowProps {
  row: Record<string, any>;
  head: ITableHeadCell[];
  menu?: JSX.Element;
  accessRowById?: (id: string) => void;
  index: number;
}

export const StyledCircle = styled(FiberManualRecord)<{ isActive: boolean }>(
  ({ isActive }) => ({
    color: isActive ? "#83e509" : "#ff000080",
    fontSize: 30,
  })
);

const renderCellContent = (
  item: any,
  row: Record<string, any>,
  menu?: JSX.Element,
  accessRowById?: (id: string) => void
) => {
  switch (item.name) {
    case "cpforcnpj":
    case "clientCpforcnpj":
    case "sellerCpforcnpj":
      return formatDocument(row[item.name]);
    case "createdAt":
    case "duedate":
    case "datecreation":
      return formatDateBr(row[item.name]);
    case "created_at":
      return formatDateBr(row[item.name]);
    case "value":
      return `R$ ${formatCurrency(row[item.name])}`;
    case "isActive":
      return <StyledCircle isActive={row[item.name]} />;
    case "actions2":
      return (
        menu &&
        accessRowById && (
          <div
            style={{ width: 30, height: 30 }}
            onClick={() => accessRowById(row.id)}
          >
            {menu}
          </div>
        )
      );
    default:
      return row[item.name];
  }
};

const DynamicTableRow: React.FC<ITableRowProps> = ({
  row,
  head,
  menu,
  accessRowById,
  index,
}) => (
  <TableRow
    key={row.id}
    sx={{ backgroundColor: index % 2 === 0 ? "white" : themeTwo.COLORS.GRAY6 }}
  >
    {head.map((item) => (
      <TableCell key={item.name} align={item.align || "left"}>
        {renderCellContent(item, row, menu, accessRowById)}
      </TableCell>
    ))}
  </TableRow>
);

export default function DataTable(props: IPropsDataTable) {
  const rows = props.data;

  useEffect(() => {}, [rows]);

  function handleSort(value: string) {
    if (value !== undefined) {
      if (props.orderBy === value) {
        props.order === "asc" ? props.setOrder("desc") : props.setOrder("asc");
      } else {
        props.setOrder("asc");
        props.setOrderBy(value);
      }
    }
  }

  return (
    <Table stickyHeader size={props.dense ? "small" : "medium"}>
      <StyledTableHead
        head={props.head}
        orderBy={props.orderBy}
        order={props.order}
        onRequestSort={handleSort}
      />
      <TableBody>
        {rows ? (
          rows.map((row, index: number) => (
            <DynamicTableRow
              key={row.id}
              row={row}
              head={props.head}
              accessRowById={props.accessRowById}
              menu={props.menu}
              index={index}
            />
          ))
        ) : (
          <Typography m="auto">Nenhum resultado encontrado</Typography>
        )}
      </TableBody>
    </Table>
  );
}
