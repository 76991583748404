import {
  Box,
  IconButton,
  TableHeadProps,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { BarLoader } from "react-spinners";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Chart from "react-google-charts";
import { useQuery } from "@tanstack/react-query";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";

import { useAppSelector } from "core/hooks/reduxHooks";
import { ClientService } from "core/api/client/clientService";
import { PageContentContainer } from "app/components/styles";
import { LIST_SALES_MONTH } from "../../../../core/utils/constants";
import { ChartsService } from "core/api/charts/chartsService";
import {
  formatDateBr,
  formatDateToChartReq,
  FormatFirstLetter,
  getMonthNameByIndex,
} from "core/utils/globalFunctions";
import theme from "core/theme/theme";
import { FinancialService } from "core/api/financial/financialService";
import {
  TBoletosStatusChartValues,
  TPixStatusChartValues,
} from "core/models/charts";
import DataPicker from "app/components/dataPicker/datePicker";
import DataTable from "app/components/table/table/table";

const Dashboard = () => {
  const navigate = useNavigate();
  const basicUserInfo = useAppSelector((state) => state.auth.userInfo);

  const [datePickerState, setdatePickerState] = useState<boolean>(false);
  const [datePickerSellerState, setDatePickerSellerState] =
    useState<boolean>(false);

  const [initDate, setInitDate] = useState<Dayjs | null | undefined>(
    dayjs().startOf("year")
  );
  const [finalDate, setFinalDate] = useState<Dayjs | null | undefined>(
    dayjs().endOf("year")
  );

  const [initSellerDate, setInitSellerDate] = useState<
    Dayjs | null | undefined
  >(dayjs().startOf("month"));
  const [finalSellerDate, setFinalSellerDate] = useState<
    Dayjs | null | undefined
  >(dayjs().endOf("month"));

  const indicationSalesQuery = useQuery({
    queryKey: ["indicationSalesQuery", initSellerDate, finalSellerDate],
    staleTime: Infinity,
    queryFn: () =>
      ChartsService.getIndicationsSales({
        dataInicial: formatDateToChartReq(initSellerDate!),
        dataFinal: formatDateToChartReq(finalSellerDate!),
      }),
  });

  const sellerSalesQuery = useQuery({
    queryKey: ["sellerSalesQuery", initSellerDate, finalSellerDate],
    staleTime: Infinity,
    queryFn: () =>
      ChartsService.getChartListSalesLiquidadas({
        dataInicial: formatDateToChartReq(initSellerDate!),
        dataFinal: formatDateToChartReq(finalSellerDate!),
      }),
  });

  // ! navegar para a página correta
  useEffect(() => {
    if (basicUserInfo) {
      if (basicUserInfo.group === "CLIENT") {
        ClientService.getDeal();
        navigate("aulas");
      } else if (basicUserInfo.group === "FINANCEIRO") {
        navigate("/vendas");
      } else if (basicUserInfo.group === "INDICATION") {
        navigate("/vendas");
      } else if (basicUserInfo.group === "SELLER") {
        navigate("/links");
      }
    } else {
      console.error("basicUserInfo is null or undefined");
    }
  }, [basicUserInfo, navigate]);

  // ! Gráficos de status
  const pixSalesStatusQuery = useQuery({
    queryKey: ["pixSalesStatusQuery", initDate, finalDate],
    staleTime: Infinity,
    queryFn: () =>
      ChartsService.getChartPixStatus({
        dataInicial: formatDateToChartReq(initDate!),
        dataFinal: formatDateToChartReq(finalDate!),
      }),
  });

  const pixSalesStatus = useMemo(() => {
    if (pixSalesStatusQuery.data) {
      const header = ["Status", "Total"];
      const infos = pixSalesStatusQuery.data.map(
        (item: TPixStatusChartValues) => [
          FormatFirstLetter(item.status),
          item.total_pix,
        ]
      );
      return [header, ...infos];
    }
    return [];
  }, [pixSalesStatusQuery]);

  const invoiceSalesStatusQuery = useQuery({
    queryKey: ["invoiceSalesStatusQuery", initDate, finalDate],
    staleTime: Infinity,
    queryFn: () =>
      ChartsService.getChartBoletosStatus({
        dataInicial: formatDateToChartReq(initDate!),
        dataFinal: formatDateToChartReq(finalDate!),
      }),
  });

  const invoiceSalesStatus = useMemo(() => {
    if (invoiceSalesStatusQuery.data) {
      const header = ["Status", "Total"];
      const infos = invoiceSalesStatusQuery.data.map(
        (item: TBoletosStatusChartValues) => [
          FormatFirstLetter(item.status),
          item.total_boletos,
        ]
      );
      return [header, ...infos];
    }
    return [];
  }, [invoiceSalesStatusQuery]);

  // ! Gráficos de renda
  const periodRentQuery = useQuery({
    queryKey: ["periodRentQuery", initDate, finalDate],
    staleTime: Infinity,
    queryFn: () =>
      FinancialService.getAllSalesInfosByRange({
        startDate: formatDateToChartReq(initDate!),
        endDate: formatDateToChartReq(finalDate!),
      }),
  });

  const periodRentPix = useMemo(() => {
    if (periodRentQuery?.data) {
      const header = ["Status", "Valor", { role: "style" }];
      const infos = [
        ["Pendente", periodRentQuery.data.pixPendenteValue, theme.COLORS.GRAY3],
        ["Pago", periodRentQuery.data.pixPagoValue, theme.COLORS.BLUE3],
      ];
      return [header, ...infos];
    }
    return [
      ["Status", "Valor", { role: "style" }],
      ["Pendente", 0, theme.COLORS.GRAY3],
      ["Pago", 0, theme.COLORS.BLUE3],
    ];
  }, [periodRentQuery]);

  const periodRentInvoice = useMemo(() => {
    if (periodRentQuery?.data) {
      const header = ["Status", "Valor", { role: "style" }];
      const infos = [
        [
          "Pendente",
          periodRentQuery.data.boletoPendenteValue,
          theme.COLORS.GRAY3,
        ],
        [
          "Em pagamento",
          periodRentQuery.data.boletoEmPagamentoValue,
          theme.COLORS.YELLOW,
        ],
        ["Pago", periodRentQuery.data.boletoPagoValue, theme.COLORS.BLUE3],
      ];
      return [header, ...infos];
    }
    return [
      ["Status", "Valor", { role: "style" }],
      ["Pendente", 0, theme.COLORS.GRAY3],
      ["Em pagamento", 0, theme.COLORS.YELLOW],
      ["Pago", 0, theme.COLORS.BLUE3],
    ];
  }, [periodRentQuery]);

  // ! Gráficos de vendas
  const yearSalesQuery = useQuery({
    queryKey: ["yearSalesQuery", initDate, finalDate],
    staleTime: Infinity,
    enabled: basicUserInfo?.roles?.includes("ROLE_ADMIN"),
    queryFn: async () =>
      await ChartsService.getChartDataByUrl(LIST_SALES_MONTH, {
        dataInicial: formatDateToChartReq(initDate!),
        dataFinal: formatDateToChartReq(finalDate!),
      }),
  });

  const yearSalesPix = useMemo(() => {
    if (yearSalesQuery.data) {
      const header = ["Mês", "Pendente", "Pago"];

      const infos = yearSalesQuery.data.map((item: any) => [
        getMonthNameByIndex(item.mes - 1),
        item.pix_pendente,
        item.pix_pago,
      ]);

      return [header, ...infos];
    }
    return [];
  }, [yearSalesQuery]);

  const yearSalesInvoice = useMemo(() => {
    if (yearSalesQuery.data) {
      const header = ["Mês", "Pendente", "Em pagamento", "Pago"];

      const infos = yearSalesQuery.data.map((item: any) => [
        getMonthNameByIndex(item.mes - 1),
        item.boleto_pendente,
        item.boleto_em_pagamento,
        item.boleto_pago,
      ]);

      return [header, ...infos];
    }
    return [];
  }, [yearSalesQuery]);

  const commonChartOptions = {
    backgroundColor: "transparent",
    animation: {
      duration: 1000,
      easing: "out",
    },
  };

  const tableRows = useMemo(() => {
    if (sellerSalesQuery.data) {
      return sellerSalesQuery.data.listMonth.map((item: any) => ({
        name: item.name,
        salesAmountBoleto: item.salesAmountBoleto,
        salesAmountPix: item.salesAmountPix,
        total: item.salesAmountBoleto + item.salesAmountPix,
      }));
    }
    return [];
  }, [sellerSalesQuery]);

  const materialTheme = useTheme();
  const isSmallScreen = useMediaQuery(materialTheme.breakpoints.down("sm"));

  return (
    <PageContentContainer>
      <Box>
        <Box
          sx={{
            borderBottom: "1px solid",
            borderColor: theme.COLORS.GRAY5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography>Período analisado</Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography sx={{ fontWeight: "bold" }}>
              {formatDateBr(formatDateToChartReq(initDate!))} -{" "}
              {formatDateBr(formatDateToChartReq(finalDate!))}
            </Typography>
            <IconButton onClick={() => setdatePickerState(true)}>
              <EditCalendarIcon sx={{ color: theme.COLORS.BLUE3 }} />
            </IconButton>
          </Box>
        </Box>
        {yearSalesQuery?.data && yearSalesQuery?.data.length > 0 ? (
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: isSmallScreen ? "1fr" : "repeat(3, 1fr)",
              gap: 2,
              borderBottom: "1px solid",
              borderColor: theme.COLORS.GRAY5,
            }}
          >
            <Chart
              chartType="ColumnChart"
              height="300px"
              data={yearSalesPix}
              options={{
                ...commonChartOptions,
                colors: [theme.COLORS.GRAY4, theme.COLORS.BLUE3],
                title: "Vendas pix",
                vAxis: {
                  title: "Quantidade",
                },
                legend: { position: "top" },
              }}
            />
            <Chart
              chartType="PieChart"
              height="300px"
              data={invoiceSalesStatus}
              options={{
                ...commonChartOptions,
                legend: { position: "top" },
                colors: [
                  theme.COLORS.BLUE3,
                  theme.COLORS.YELLOW,
                  theme.COLORS.RED,
                ],
                title: "Stauts vendas boleto",
              }}
            />
            <Chart
              chartType="ColumnChart"
              height="300px"
              data={periodRentPix}
              options={{
                ...commonChartOptions,
                title: "Renda pix",
                legend: { position: "none" },
                vAxis: {
                  title: "Valor",
                  minValue: 0,
                },
                isStacked: false,
              }}
            />
            <Chart
              chartType="ColumnChart"
              height="300px"
              data={yearSalesInvoice}
              options={{
                ...commonChartOptions,
                colors: [
                  theme.COLORS.GRAY4,
                  theme.COLORS.YELLOW,
                  theme.COLORS.BLUE3,
                ],
                title: "Vendas boleto",
                vAxis: {
                  gridlines: { count: 4 },
                  title: "Quantidade",
                },
                legend: { position: "top" },
              }}
            />
            <Chart
              chartType="PieChart"
              height="300px"
              data={pixSalesStatus}
              options={{
                ...commonChartOptions,
                legend: { position: "top" },
                colors: [
                  theme.COLORS.BLUE3,
                  theme.COLORS.YELLOW,
                  theme.COLORS.RED,
                ],
                title: "Stauts vendas pix",
              }}
            />
            <Chart
              chartType="ColumnChart"
              height="300px"
              data={periodRentInvoice}
              options={{
                ...commonChartOptions,
                title: "Renda boleto",
                legend: { position: "none" },
                vAxis: {
                  title: "Valor",
                },
                isStacked: false,
              }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              height: "70svh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <BarLoader color={theme.COLORS.BLUE3} />
          </Box>
        )}
        <Box>
          <Box
            sx={{
              borderBottom: "1px solid",
              borderColor: theme.COLORS.GRAY5,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography>Vendas por vendedor & indicação</Typography>
            <Typography>Período analisado</Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>
                {formatDateBr(formatDateToChartReq(initSellerDate!))} -
                {formatDateBr(formatDateToChartReq(finalSellerDate!))}
              </Typography>
              <IconButton onClick={() => setDatePickerSellerState(true)}>
                <EditCalendarIcon sx={{ color: theme.COLORS.BLUE3 }} />
              </IconButton>
            </Box>
          </Box>
          {sellerSalesQuery.isLoading && indicationSalesQuery.isLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "70vh",
              }}
            >
              <BarLoader color={theme.COLORS.BLUE3} />
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                gap: 2,
                height: "50vh",
                overflow: "hidden",
                flexDirection: isSmallScreen ? "column" : "row",
                width: isSmallScreen ? "80svw" : "100%",
              }}
            >
              <Box
                sx={{
                  flex: 1,
                  overflowY: "auto",
                }}
              >
                <DataTable
                  dense={true}
                  head={tableHead}
                  data={tableRows}
                  orderBy="name"
                  order="asc"
                  setOrder={(order) => console.log("Set order:", order)}
                  setOrderBy={(orderBy) =>
                    console.log("Set order by:", orderBy)
                  }
                />
              </Box>
              <Box
                sx={{
                  flex: 1,
                  overflowY: "auto",
                }}
              >
                <DataTable
                  dense={true}
                  head={tableIndicationHead}
                  data={indicationSalesQuery.data}
                  orderBy="inidcationName"
                  order="asc"
                  setOrder={(order) => console.log("Set order:", order)}
                  setOrderBy={(orderBy) =>
                    console.log("Set order by:", orderBy)
                  }
                />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <DataPicker
        typeOfDatePicker={"year"}
        title="Filtrar data"
        isOpen={datePickerState}
        onOpen={() => setdatePickerState(true)}
        onClose={() => setdatePickerState(false)}
        initialDate={initDate}
        endDate={finalDate}
        setFinalDate={setFinalDate}
        setInitialDate={setInitDate}
      />
      <DataPicker
        typeOfDatePicker={"month"}
        title="Filtrar data"
        isOpen={datePickerSellerState}
        onOpen={() => setDatePickerSellerState(true)}
        onClose={() => setDatePickerSellerState(false)}
        initialDate={initSellerDate}
        endDate={finalSellerDate}
        setFinalDate={setFinalSellerDate}
        setInitialDate={setInitSellerDate}
      />
    </PageContentContainer>
  );
};

export default Dashboard;

const tableHead = [
  { name: "name", label: "Nome" },
  { name: "salesAmountBoleto", label: "Boletos" },
  { name: "salesAmountPix", label: "Pix" },
  { name: "total", label: "Total" },
];

const tableIndicationHead = [
  { name: "indicationName", label: "Nome" },
  { name: "totalSales", label: "Vendas" },
];

interface MonthSales {
  boleto_em_pagamento: number;
  mes: number;
  pix_pendente: number;
  pix_pago: number;
  boleto_pendente: number;
  boleto_pago: number;
}
