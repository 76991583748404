import { Box, Grid, Paper, Typography } from "@mui/material";
import styled from "styled-components";
import LogoMin from "images/assets/logoPqn.png";

export const ContainerCard = styled(Grid)`
    background-color: #fff;
    padding: 1em;
    margin-top: 10px;
    margin-left: 15px;
    border-radius: 0.5em;
    width: 370px;
    display: flex !important;
    flex-direction: column !important;
    justify-content: flex-start !important;
    box-shadow: 2px 2px 4px 3px rgba(0, 0, 0, 0.3);
`;

export const TypographyCard = styled(Typography)`
    padding: 1rem;
`;

export const BoxInfo = styled(Box)`
    display: flex !important;
    padding-bottom: 0.5rem;
    flex: 1;
    align-items: center;
    justify-content: center;
    flex-direction: row !important;
    justify-content: flex-start !important;
`;
export const BoxIcon = styled(Box)`
    background-image: url(${LogoMin});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 60px;
    height: 60px;
`;

export const HoursAlign = styled(Box)`
    display: flex;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: flex-end;
    margin-top: 8px;
    color: ${({ theme }) => theme.COLORS.BLUE3} !important;
`;

