import { useCallback } from "react";

import { TSaleType } from "app/views/dashboard/linksDisplay/linksDisplay";
import { SellerService } from "core/api/seller/sellerService";

export const useLinkGenerator = (linkManagers: any, modalManagers: any) => {
  const {
    linkRequest,
    linkRequestLimpaNomeOnlyCupom,
    linkRequestOnlyIndication,
    setLinkCupomAndIndication,
    setLinkCupom,
    setLinkConsultoria,
  } = linkManagers;

  const {
    setModalLinkCupomIndication,
    setModalLinkCupom,
    setModalLinkIndicationConsultoria,
  } = modalManagers;

  const handleGenerateLink = useCallback(
    async (withIndication: boolean, type: TSaleType) => {
      try {
        if (withIndication && type === "limpaNome") {
          const resp = await SellerService.generateLink(linkRequest);
          setLinkCupomAndIndication(resp.data);
          setModalLinkCupomIndication(false);
        } else if (!withIndication && type === "limpaNome") {
          const resp = await SellerService.generateLink(
            linkRequestLimpaNomeOnlyCupom
          );
          setLinkCupom(resp.data);
          setModalLinkCupom(false);
        } else {
          const resp = await SellerService.generateLink(
            linkRequestOnlyIndication
          );
          setLinkConsultoria(resp.data);
          setModalLinkIndicationConsultoria(false);
        }
      } catch (error) {
        console.error("Erro ao gerar o link:", error);
      }
    },
    [
      linkRequest,
      linkRequestLimpaNomeOnlyCupom,
      linkRequestOnlyIndication,
      setLinkCupomAndIndication,
      setLinkCupom,
      setLinkConsultoria,
      setModalLinkCupomIndication,
      setModalLinkCupom,
      setModalLinkIndicationConsultoria,
    ]
  );

  return { handleGenerateLink };
};
