import { useAppSelector } from "core/hooks/reduxHooks";
import { TRole } from "core/utils/roles";

/*
    Hook básico para verificar se o usuário tem acesso a uma determinada rota.
*/

interface VerifyAccessParams {
  allowedRoles: TRole[];
  restrictedGroup: string;
}

export const useVerifyAccess = ({
  allowedRoles,
  restrictedGroup,
}: VerifyAccessParams): boolean => {
  const { roles, group } = useAppSelector((state) => state.auth.userInfo) ?? {};

  if (!roles || group === restrictedGroup) return false;
  return (
    roles.includes("ROLE_ADMIN") ||
    allowedRoles.some((role) => roles.includes(role))
  );
};
