import {
  Box,
  Divider,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import DefaultFilter, {
  ISelectItem,
} from "app/components/filter/defaultFilter";
import { PageContentContainer } from "app/components/styles";
import DataTablePagination from "app/components/table/pagination/pagination";
import TableHeader from "app/components/table/tableHeader/TableHeader";
import { ContentBody } from "app/views/sellerList/styles";
import useFinancialFilterHook from "core/hooks/filters/financialHook";
import { ITableHeadCell } from "core/models/table";
import Search from "app/components/search/Search";
import { StyledDivDataTable } from "app/components/table/tableHead/styles";
import Spinner from "app/components/spinner/spinner";
import { SalesCell, SalesTypography } from "app/views/sales/styles";
import {
  formatarData,
  formatarDataSemHoras,
  formatCurrencyBR,
  formatDateBr,
  formatDocument,
  maxNameSize,
} from "core/utils/globalFunctions";
import StyledStatus from "app/components/styledStatus/styledStatus";
import theme from "core/theme/theme";
import { useDateFilter } from "../../../../core/context/financialContext";
import styled from "styled-components";

export const head: ITableHeadCell[] = [
  { name: "sellerName", label: "Nome do vendedor", align: "left" },
  { name: "sellerCpforcnpj", label: "Vendedor", align: "left" },
  { name: "clientCpforcnpj", label: "Cliente", align: "left" },
  { name: "value", label: "Valor", align: "left" },
  { name: "txid", label: "txid", align: "left" },
  { name: "duedate", label: " Data vencimento", align: "left" },
  { name: "datecreation", label: "Data criação", align: "left" },
  { name: "status", label: "Status", align: "left" },
];

export const items: ISelectItem[] = [
  { name: "CPF / CNPJ", value: "clientcpforcnpj", type: "texto" },
  { name: "Status", value: "status", type: "status2" },
  { name: "Data de pagamento", value: "daterange", type: "daterange" },
];

const FinancialPix = () => {
  const {
    countPix,
    filterPix,
    pagePix,
    pixSales,
    rowsPerPagePix,
    setFilterPix,
    setPagePix,
    setRowsPerPagePix,
    salesInfos,
    isLoading,
  } = useDateFilter();

  const { isOpen, onClose, onOpen } = useFinancialFilterHook();

  // remover filtro
  const removeFilter = (attribute: string) => {
    setFilterPix((prevState) => ({
      ...prevState,
      [attribute]: undefined,
    }));
  };

  return (
    <PageContentContainer>
      <TableHeader
        filter={filterPix}
        filterBtn={true}
        filterBtnAction={() => onOpen()}
        remove={removeFilter}
        extraComponents={
          <Search
            searchPlaceHolder="Cpf/cnpj do cliente..."
            cpfClient={filterPix.clientcpforcnpj}
            querrySearching={pixSales.isLoading}
            onChange={(e: string) =>
              setFilterPix((prevState) => ({
                ...prevState,
                clientcpforcnpj: e,
              }))
            }
          />
        }
      />

      {!isLoading && !filterPix.clientcpforcnpj && !filterPix.status && (
        <InfoSection>
          <DateText>
            {`${formatDateBr(filterPix.dateStart!)} - ${formatDateBr(
              filterPix.dateEnd!
            )}`}
          </DateText>
          <InfosContainer>
            <InfosBox>
              <Typography
                sx={{ fontWeight: "bold", color: theme.COLORS.GREEN2 }}
              >
                Pagos
              </Typography>
              <Typography sx={{ color: theme.COLORS.GREEN2 }}>
                {salesInfos?.pixPagoCount}
              </Typography>
              <Typography sx={{ fontSize: "1pc", color: theme.COLORS.GREEN2 }}>
                Total: {formatCurrencyBR(salesInfos?.pixPagoValue)}
              </Typography>
            </InfosBox>
            <InfosBox>
              <Typography
                sx={{ fontWeight: "bold", color: theme.COLORS.GRAY3 }}
              >
                Pendentes
              </Typography>
              <Typography sx={{ fontSize: "1pc", color: theme.COLORS.GRAY3 }}>
                {salesInfos?.pixPendenteCount}
              </Typography>
              <Typography sx={{ fontSize: "1pc", color: theme.COLORS.GRAY3 }}>
                Total: {formatCurrencyBR(salesInfos?.pixPendenteValue)}
              </Typography>
            </InfosBox>
          </InfosContainer>
        </InfoSection>
      )}

      <ContentBody>
        {pixSales.isLoading ? (
          <Box sx={{ position: "relative", height: 500 }}>
            <Spinner
              state={pixSales.isLoading}
              size={10}
              css={{
                position: "absolute",
                top: "50%",
                left: "50%",
              }}
            />
          </Box>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <StyledDivDataTable>Cliente</StyledDivDataTable>
                <StyledDivDataTable>Vendedor</StyledDivDataTable>
                <StyledDivDataTable>Valor</StyledDivDataTable>
                <StyledDivDataTable>Txid</StyledDivDataTable>
                <StyledDivDataTable>Datas</StyledDivDataTable>
                <StyledDivDataTable>Status</StyledDivDataTable>
              </TableRow>
            </TableHead>
            <TableBody>
              {pixSales.data?.content.map((row: any, index: any) => (
                <TableRow
                  key={row.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    backgroundColor:
                      index % 2 === 0 ? "white" : theme.COLORS.GRAY6,
                  }}
                >
                  <SalesCell component="th" scope="row" size="small">
                    <Tooltip title={row.clientName}>
                      <SalesTypography fontWeight={"bold"}>
                        {maxNameSize(row.clientName, 32)}
                      </SalesTypography>
                    </Tooltip>
                    <SalesTypography>
                      {formatDocument(row.clientCpforcnpj)}
                    </SalesTypography>
                  </SalesCell>
                  <SalesCell component="th" scope="row" size="small">
                    <Tooltip title={row.sellerName}>
                      <SalesTypography fontWeight={"bold"}>
                        {maxNameSize(row.sellerName, 35)}
                      </SalesTypography>
                    </Tooltip>
                    <SalesTypography>
                      {formatDocument(row.sellerCpforcnpj)}
                    </SalesTypography>
                  </SalesCell>
                  <SalesCell component="th" scope="row" size="small">
                    <SalesTypography>
                      {formatCurrencyBR(row.value)}
                    </SalesTypography>
                  </SalesCell>
                  <SalesCell
                    component="th"
                    scope="row"
                    size="small"
                    width={220}
                  >
                    <SalesTypography>{row.txid}</SalesTypography>
                  </SalesCell>
                  <SalesCell
                    component="th"
                    scope="row"
                    size="small"
                    width={220}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginRight: "25px",
                        gap: 1,
                      }}
                    >
                      <SalesTypography sx={{ width: "70px" }}>
                        Criação:
                      </SalesTypography>
                      <SalesTypography fontWeight={"bold"}>
                        {row.datecreation
                          ? formatarDataSemHoras(row.datecreation)
                          : "--"}
                      </SalesTypography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginRight: "25px",
                        gap: 1,
                      }}
                    >
                      <SalesTypography sx={{ width: "70px" }}>
                        Vencimento:
                      </SalesTypography>
                      <SalesTypography fontWeight={"bold"}>
                        {row.duedate ? formatarDataSemHoras(row.duedate) : "--"}
                      </SalesTypography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginRight: "25px",
                        gap: 1,
                      }}
                    >
                      <SalesTypography sx={{ width: "70px" }}>
                        Pagamento:
                      </SalesTypography>
                      <SalesTypography fontWeight={"bold"}>
                        {row.paymentDate ? formatarData(row.paymentDate) : "--"}
                      </SalesTypography>
                    </Box>
                  </SalesCell>
                  <SalesCell component="th" scope="row" size="small">
                    <SalesTypography>
                      <StyledStatus status={row.status} />
                    </SalesTypography>
                  </SalesCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
        <DataTablePagination
          setPage={setPagePix}
          page={pagePix}
          setRowsPerPage={setRowsPerPagePix}
          rowsPerPage={rowsPerPagePix}
          count={countPix}
        />
      </ContentBody>
      <DefaultFilter
        isOpen={isOpen}
        items={items}
        onChangeFilter={setFilterPix}
        onClose={onClose}
        onOpen={onOpen}
        title="Filtrar transações"
      />
    </PageContentContainer>
  );
};

export default FinancialPix;

const InfoSection = styled(Box)`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const DateText = styled(Typography)`
  color: ${theme.COLORS.BLUE3} !important;
  font-size: 1.1pc !important;
  font-weight: bold !important;
  position: absolute !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  top: 20px !important;
`;

const InfosContainer = styled(Box)`
  background-color: #fff;
  display: flex;
  flex-direction: row;
`;

const InfosBox = styled(Box)`
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 0 1%;
`;
