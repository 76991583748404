import { Box, IconButton, Tooltip } from '@mui/material';
import Search from 'app/components/search/Search';
import { PageContentContainer } from 'app/components/styles';
import TableHeader from 'app/components/table/tableHeader/TableHeader';
import React, { useEffect, useState } from 'react'
import { ContentBody } from '../sellerList/styles';
import Spinner from 'app/components/spinner/spinner';
import DataTable from 'app/components/table/table/table';
import DataTablePagination from 'app/components/table/pagination/pagination';
import DefaultFilter from 'app/components/filter/defaultFilter';
import DefaultMenu, { IMenuItemProps } from 'app/components/menu/DefaultMenu';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'core/hooks/reduxHooks';
import { TClientFilterRequest } from 'core/models/client';
import { ITableHeadCell, Order } from 'core/models/table';
import { TRole, verifyRole } from 'core/utils/roles';
import { fetchClients } from 'core/querryes/client/getClientQuerrt';
import { removeNonNumeric } from 'core/utils/globalFunctions';
import { ClientService } from 'core/api/client/clientService';
import { Notification } from 'app/components/toastNotification/toastNotification';
import { AxiosError } from 'axios';
import { useQuery } from "@tanstack/react-query";
import MoreHorizRounded from "@mui/icons-material/MoreHorizRounded";

const Clients = () => {
    const navigate = useNavigate();
    const basicUserInfo = useAppSelector((state) => state.auth.userInfo);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);
    const [order, setOrder] = useState<Order>("asc");
    const [orderBy, setOrderBy] = useState("name");
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [tempSeller, setTempSeller] = useState<any>();
    const open = Boolean(anchorEl);
    const [filters, setFilters] = useState<TClientFilterRequest>({
        cpforCnpj: "",
        name: undefined,
    });

    const head: ITableHeadCell[] = [
        { name: "name", label: "Nome", align: "left" },
        { name: "cpforcnpj", label: "CPF", align: "left" },
        { name: "email", label: "Email", align: "left" },
        { name: "created_at", label: "Data de criação", align: "left", date: true },
        { name: "isActive", label: "Ativo", align: "left" },
        ...(basicUserInfo?.group === "ADMIN"
            ? [{ name: "actions2", label: "Opções", align: "left" } as ITableHeadCell]
            : []),
    ];

    const handleAccessRowById = (id: string) => {
        setTempSeller(data?.content.find((brand: any) => brand.id === id))
    }

    const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };


    const { isSuccess, isLoading, data, refetch } = useQuery({
        queryKey: ["clients", page, rowsPerPage, orderBy, order, filters],
        queryFn: () =>
            fetchClients(
                page,
                rowsPerPage,
                orderBy,
                order,
                filters.name,
                filters.cpforCnpj
            ),
    });

    const setPassword = async () => {
        await ClientService.setDefaultPassword(tempSeller?.id ?? "").then((resp) => {
            Notification(resp, "success");
            console.log(resp);
            handleCloseMenu();
            setTempSeller(undefined);
            refetch();
        }).catch((err: AxiosError) => {
            Notification(err.message, "error");
        });
    };

    const items: IMenuItemProps[] = [
        {
            function: () => {
                setPassword();
                handleCloseMenu();
            },
            label: "Resetar Senha",
        },
    ];

    const removeFilter = (attribute: string) => {
        setFilters((prevState) => ({
            ...prevState,
            [attribute]: undefined,
        }));
    };

    useEffect(() => {
        if (isSuccess && data) {
            setCount(data.totalElements);
        }
    }, [isSuccess, data]);

    if (!verifyRole(basicUserInfo?.roles, ["ROLE_ADMIN"])) {
        navigate(-1);
    }


    return (
        <PageContentContainer>
            <TableHeader
                filterBtn={true}
                filter={filters}
                remove={removeFilter}
                extraComponents={
                    <Search
                        searchPlaceHolder="Cpf/cnpj do cliente..."
                        querrySearching={isLoading}
                        cpfClient={filters.cpforCnpj}
                        onChange={(e: string) => setFilters((prevState) => ({
                            ...prevState,
                            cpforCnpj: e,
                        }))}
                    />
                }
            />
            <ContentBody>
                {isLoading ? (
                    <Box sx={{ position: "relative", height: 500 }}>
                        <Spinner
                            state={isLoading}
                            size={10}
                            css={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                            }}
                        />
                    </Box>
                ) : (
                    <DataTable
                        head={head}
                        data={data?.content}
                        order={order}
                        orderBy={orderBy}
                        setOrder={setOrder}
                        setOrderBy={setOrderBy}
                        accessRowById={handleAccessRowById}
                        menu={
                            <Tooltip title="Opções">
                                <IconButton onClick={handleClickMenu}>
                                    <MoreHorizRounded />
                                </IconButton>
                            </Tooltip>
                        }
                    />
                )}
                <DataTablePagination
                    setPage={setPage}
                    page={page}
                    setRowsPerPage={setRowsPerPage}
                    rowsPerPage={rowsPerPage}
                    count={count}
                />
            </ContentBody>
            <DefaultMenu
                anchor={anchorEl}
                menuItems={items}
                onClose={handleCloseMenu}
                status={open}
            />
        </PageContentContainer>
    );
}

export default Clients