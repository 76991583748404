import { Box, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import theme from 'core/theme/theme';
import React, { useEffect, useRef, useState } from 'react'
import Countdown from 'react-countdown';
import QRCode from 'react-qr-code';
import styled from 'styled-components';
import { keyframes } from 'styled-components';
import copy from "clipboard-copy";
import { Notification } from 'app/components/toastNotification/toastNotification';
import { axiosInstance } from 'core/api/axios/axiosInstance';
import { SALE } from 'core/utils/constants';
import { LoginContainer, LoginContentBox, LoginLogo } from './styles';

const PayPendentPaymet = () => {

  const params = new URLSearchParams(window.location.search);
  const qrCode = params.get("qrCode");
  const idSale = params.get("idSale");
  const [isClicked, setIsClicked] = useState(false);
  const [counter, setCounter] = useState(10);
  const materialTheme = useTheme();
  const isSmallScreen = useMediaQuery(materialTheme.breakpoints.down("sm"));

  // UseRef para armazenar a data de expiração
  const expirationTime = useRef(Date.now() + 600000);

  const handleCopyClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    try {
      setIsClicked(true);
      await copy(qrCode!);
      setTimeout(() => {
        setIsClicked(false);
      }, 500);
    } catch (err) {
      console.error("Erro ao copiar o texto:", err);
    }
  };

  const checkPaymentStatus = () => {
    axiosInstance.get(SALE + "/pix/" + idSale).then((resp) => {
      if (resp.data.data.isPayment === true) {
        Notification("Pagamento concluído", "success");
        window.location.pathname = "/login";
      } else {
        Notification("Aguardando pagamento", "warning");
      }
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((prevCounter) => prevCounter - 1);
      if (counter === 0) {
        checkPaymentStatus();
        setCounter(10);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [counter]);

  return (

    <LoginContainer
      sx={{ display: "flex" }}
      $small={isSmallScreen}
      maxWidth={isSmallScreen ? "sm" : "md"}
      disableGutters
    >
      <LoginLogo $small={isSmallScreen}></LoginLogo>

      <LoginContentBox $small={isSmallScreen}>
      <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%"
          }}
        >
      <Typography sx={{ color: theme.COLORS.BLUE2, fontWeight: "bolder", fontSize: "21px" }}>Não perca tempo! Efetue o pagamento e aproveite a promoção enquanto está disponível!</Typography>
      </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            alignItems: "center",
            justifyContent: "center",
            gap: 2,
          }}
        >

          {!isSmallScreen &&
              <QRCodeContainer>
                <QRCode value={qrCode!} size={160} />
              </QRCodeContainer>}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: 250,
              alignItems: "center",
              justifyContent: "space-around",
              gap: 2,
            }}
          >
            <CounterContainer>
              <CounterTitle>Sua Chave PIX Expira em:</CounterTitle>
              <Countdown
                date={expirationTime.current}
                renderer={({ minutes, seconds, completed }) => {
                  if (completed) {
                    return
                  } else {
                    return (
                      <div className="timer-container">
                        <div className="timer">
                          <span className="timer-value">
                            {String(minutes).padStart(2, '0')}
                          </span>
                          <span className="timer-label">Minutos</span>
                        </div>
                        <div className="timer">
                          <span className="timer-value">
                            {String(seconds).padStart(2, '0')}
                          </span>
                          <span className="timer-label">Segundos</span>
                        </div>
                      </div>
                    );
                  }
                }}
              />
            </CounterContainer>
            <Paper
              sx={{
                height: 100,
                width: "80%",
                overflowWrap: "break-word",
                overflowY: "scroll",
                padding: 1,
                border: "2px solid #000",
              }}
            >
              {qrCode}
            </Paper>

            <StyledButton isClicked={isClicked} onClick={(event) => handleCopyClick(event)}>
              {isClicked ? "Pix copiado" : "Copiar pix"}
            </StyledButton>
          </Box>
        </Box>
      </LoginContentBox>
    </LoginContainer>

  )
}

export default PayPendentPaymet

const QRCodeContainer = styled(Box)`
  display: inline-block;
  padding: 0.5rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.06);
  border: 1px solid #e0e0e0;
  transform: translateY(-2px);

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.15), 0px 3px 6px rgba(0, 0, 0, 0.1);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.08);
  }
`;

const fadeToGreen = keyframes`
  0% {
    background-color: ${theme.COLORS.WHITE};
    color: ${theme.COLORS.BLUE3};
  }
  100% {
    background-color: #569C00;
    color: ${theme.COLORS.WHITE};
  }
`;

const StyledButton = styled.button<{ isClicked: boolean }>`
  background-color: ${({ isClicked }) =>
    isClicked ? "#569C00" : theme.COLORS.WHITE};
  color: ${({ isClicked }) =>
    isClicked ? theme.COLORS.WHITE : theme.COLORS.BLUE3};
  padding: 0.8rem 1.2rem;
  border: 2px solid
    ${({ isClicked }) => (isClicked ? "#569C00" : theme.COLORS.BLUE3)};
  border-radius: 8px;
  font-weight: bold;
  width: 80%;
  font-size: 1rem;
  transition: all 0.3s ease-in-out;
  animation: ${({ isClicked }) => (isClicked ? fadeToGreen : "none")} 0.3s
    ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: ${theme.COLORS.WHITE};
    color: ${theme.COLORS.BLUE3};
  }

  &:active {
    transform: scale(0.95);
  }
`;


const CounterContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.3rem;
`;
const CounterTitle = styled(Typography)`
  font-size: 0.8rem !important;
  font-weight: bold !important;
  color: ${theme.COLORS.BLUE2};
`;