import { useMemo } from "react";
import { useSaleFormContext } from "core/context/SaleContext";
import { formatCurrencyBR, removeNonNumeric } from "core/utils/globalFunctions";
import { useSaleUrl } from "./useSaleUrl";

const useSaleTitle = () => {
  const { formik, tempCupom, paymentInfo } = useSaleFormContext();
  const { saleTypeByUrl } = useSaleUrl();

  const cleanDocument = removeNonNumeric(formik.values.cpforcnpj) ?? "";

  const saleTitle = useMemo(() => {
    if (saleTypeByUrl === "CONSULTORIA") {
      return formatCurrencyBR(297);
    }

    const isIndividual = cleanDocument.length === 11;
    const isCompany = cleanDocument.length === 14;

    if (isIndividual) {
      return paymentInfo.isFees
        ? formatCurrencyBR(1600)
        : formatCurrencyBR(1500);
    }

    if (isCompany) {
      return paymentInfo.isFees
        ? formatCurrencyBR(2100)
        : formatCurrencyBR(2000);
    }

    return formatCurrencyBR(0);
  }, [cleanDocument, saleTypeByUrl, tempCupom, paymentInfo]);

  return saleTitle;
};

export default useSaleTitle;
