import {
  Container,
  Paper,
  FormControlLabel,
  Checkbox,
  useMediaQuery,
  useTheme,
  Box,
} from "@mui/material";
import { FormikProps } from "formik";
import { TNewClientBodyRequest } from "core/models/client";
import React from "react";
import { ClientClearNameDeal } from "./deal";
import { formatCEP, removeNonNumeric } from "core/utils/globalFunctions";
import { useSaleFormContext } from "core/context/SaleContext";
import { ClientCoursesDeal } from "app/components/coursesDeal/coursesDeal";
import styled from "styled-components";

interface IProps {
  formik: FormikProps<TNewClientBodyRequest>;
  isCheckboxEnabled: boolean;
  setCheckboxEnabled: React.Dispatch<React.SetStateAction<boolean>>;
}

const AssignDeal = (props: IProps) => {
  const { tempCupom } = useSaleFormContext();
  const currentTheme = useTheme();
  const isSmallScreen = useMediaQuery(currentTheme.breakpoints.down("sm"));

  // ? titulo do form com base na url
  function saleTtype(): string {
    let urlType = window.location.href.split("/");
    let productType =
      urlType[3] === "consultoria" ? "CONSULTORIA" : "LIMPANOME";
    return productType;
  }

  // ? função que diz o preço da venda
  function salePrice(
    saleType: string,
    documentType: string,
    paymentType: string,
    tempCupom?: any
  ): number {
    let price: number;
    if (saleType === "CONSULTORIA") {
      price = 297;
    } else {
      const prices: any = {
        PIX: {
          CPF: 1500,
          CNPJ: 2000,
        },
        BOLETO: {
          CPF: 800,
          CNPJ: 1050,
        },
      };
      price = prices[paymentType]?.[documentType] || 0;
      if (Object.keys(tempCupom).length > 0) {
        if (paymentType === "BOLETO" && documentType === "CPF") {
          price = Number(tempCupom.valueInstallmentCpf);
        }
        if (paymentType === "BOLETO" && documentType === "CNPJ") {
          price = Number(tempCupom.valueInstallmentCnpj);
        }
        if (paymentType === "PIX" && documentType === "CPF") {
          price = Number(tempCupom.valuePixCpf);
        }
        if (paymentType === "PIX" && documentType === "CNPJ") {
          price = Number(tempCupom.valuePixCnpj);
        }
      }
    }
    return price;
  }

  return (
    <StyledContainer>
      <Box
        sx={{
          flex: 1,
          maxHeight: "55svh",
          overflowY: "scroll",
          overfloX: "hidden",
        }}
      >
        {saleTtype() === "LIMPANOME" ? (
          <ClientClearNameDeal
            clientName={props.formik.values.name}
            clientDocument={props.formik.values.cpforcnpj}
            fullValue={salePrice(
              saleTtype(),
              removeNonNumeric(props.formik.values.cpforcnpj)!.length === 11
                ? "CPF"
                : "CNPJ",
              "PIX",
              tempCupom
            )}
            halfValue={salePrice(
              saleTtype(),
              removeNonNumeric(props.formik.values.cpforcnpj)!.length === 11
                ? "CPF"
                : "CNPJ",
              "BOLETO",
              tempCupom
            )}
            clientAddressAsString={`${props.formik.values.address}, ${
              props.formik.values.neighborhood
            }, ${props.formik.values.city}, ${
              props.formik.values.uf
            }, ${formatCEP(props.formik.values.cep)}`}
          />
        ) : (
          <ClientCoursesDeal
            clientName={props.formik.values.name}
            clientDocument={props.formik.values.cpforcnpj}
            clientAddressAsString={`${props.formik.values.address}, ${
              props.formik.values.neighborhood
            }, ${props.formik.values.city}, ${
              props.formik.values.uf
            }, ${formatCEP(props.formik.values.cep)}`}
          />
        )}
      </Box>

      <FormControlLabel
        control={
          <Checkbox
            checked={props.isCheckboxEnabled}
            onChange={() => props.setCheckboxEnabled(!props.isCheckboxEnabled)}
          />
        }
        label="Aceitar Termos e Condições"
      />
    </StyledContainer>
  );
};

export default AssignDeal;

const StyledContainer = styled(Box)<{ mobile?: boolean }>`
  display: flex;
  flex: 1;
  width: ${({ mobile }) => (mobile ? "100%" : "50%")};
  flex-direction: column;
`;
