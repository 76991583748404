import copy from "clipboard-copy";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { IconButton, Typography } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { InnerSection, LinkText, Section } from "./styles";
import { fetchLinks } from "core/querryes/dashboard/getLinksQuerry";
import { LINK_VENDA_CURSO, LINK_VENDA_LIMPA_NOME } from "core/utils/constants";
import theme from "core/theme/theme";

interface LinkDefaultProps {
  linkType: string;
}

const LinkDefault = ({ linkType }: Readonly<LinkDefaultProps>) => {
  const { data, isLoading } = useQuery({
    queryKey: ["links"],
    staleTime: Infinity,
    queryFn: () => fetchLinks(),
  });

  return (
    <Section>
      <Typography>Link padrão :</Typography>
      <InnerSection>
        {isLoading ? (
          <LinkText>Carregando...</LinkText>
        ) : (
          <Link
            target="_blank"
            to={`/${linkType}/` + data?.data.CAMPANHA26022024}
          >
            <LinkText>
              {linkType === "consultoria"
                ? LINK_VENDA_CURSO
                : LINK_VENDA_LIMPA_NOME}
            </LinkText>
          </Link>
        )}
        <IconButton
          disabled={isLoading}
          onClick={() =>
            copy(
              `${
                linkType === "consultoria"
                  ? LINK_VENDA_CURSO
                  : LINK_VENDA_LIMPA_NOME
              }${data?.data.CAMPANHA26022024}`
            )
          }
        >
          <ContentCopyIcon
            sx={{ color: isLoading ? theme.COLORS.GRAY4 : theme.COLORS.BLUE3 }}
          />
        </IconButton>
      </InnerSection>
    </Section>
  );
};

export default LinkDefault;
