import { useMemo } from "react";

const useSaleTitle = () => {
  const title = useMemo(() => {
    const urlType = window.location.href.split("/");
    return urlType[3] === "consultoria" ? "Consultoria" : "Limpa nome";
  }, []);

  return title;
};

export default useSaleTitle;
