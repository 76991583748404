import { useSaleFormContext } from "core/context/SaleContext";
import AssignDeal from "./assignDeal";

/* 
    Aqui é mostrato o contato de venda
*/

const SaleContract = () => {
  const { formik, isChecked, setIsChecked } = useSaleFormContext();
  return (
    <AssignDeal
      formik={formik}
      isCheckboxEnabled={isChecked}
      setCheckboxEnabled={setIsChecked}
    />
  );
};

export default SaleContract;
