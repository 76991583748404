import React from "react";
import {
  Modal,
  Box,
  IconButton,
  styled,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import theme from "core/theme/theme";

const colors = {
  background: "#f7f7f7",
  border: theme.COLORS.BLUE3,
  shadow: "#000000",
  text: "#000000",
  primary: theme.COLORS.BLUE3,
};

// Estilos com styled-components
const StyledModalBox = styled(Box)(
  ({ width, height }: { width: string; height: string }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width,
    height,
    backgroundColor: colors.background,
    border: `4px solid ${colors.border}`,
    borderRadius: "12px",
    // boxShadow: `8px 8px 0px ${colors.shadow}`,
    display: "flex",
    flexDirection: "column",
    color: colors.text,
    overflow: "hidden",
  })
);

const StyledHeader = styled(Box)({
  width: "100%",
  backgroundColor: colors.primary,
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  borderTopLeftRadius: "8px",
  borderTopRightRadius: "8px",
  padding: "5px 8px",
  position: "relative",
});

const StyledContent = styled(Box)({
  flex: 1,
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

const ModalTitle = styled(Typography)({
  color: "#ffffff",
  textAlign: "center",
  position: "absolute",
  left: "50%",
  transform: "translateX(-50%)",
  fontWeight: "bold",
  fontSize: "1.4pc",
});

const StyledCloseButton = styled(IconButton)({
  color: theme.COLORS.WHITE,
});

interface GenericModalProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  width?: string;
  height?: string;
  title?: string;
}

const GenericModal: React.FC<GenericModalProps> = ({
  open,
  onClose,
  children,
  width = "800px",
  height = "500px",
  title,
}) => {
  const isSmallScreen = useMediaQuery("(max-width:900px)");

  return (
    <Modal open={open} onClose={onClose}>
      <StyledModalBox
        width={width}
        height={height}
        sx={{ overflowY: isSmallScreen ? "scroll" : "hidden" }}
      >
        {/* Barra superior */}
        <StyledHeader>
          {title && <ModalTitle>{title}</ModalTitle>}
          <StyledCloseButton onClick={onClose}>
            <CloseIcon />
          </StyledCloseButton>
        </StyledHeader>
        {/* Conteúdo do modal */}
        <StyledContent>{children}</StyledContent>
      </StyledModalBox>
    </Modal>
  );
};

export default GenericModal;
