import * as React from "react";
import styled from "styled-components";
import { useQuery } from "@tanstack/react-query";
import CircularProgress from "@mui/material/CircularProgress";
import {
    Autocomplete,
    Box,
    Button,
    Divider,
    IconButton,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import { debounce, set } from "lodash";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState, useMemo } from "react";
import { CSSTransition } from "react-transition-group";
import SaveIcon from "@mui/icons-material/Save";

import DefaultModal from "../../components/modals/defaultModal/defaultModal";
import { DisplayList } from "./DisplayList";
import { downloadCSVFromBase64, formatDateBr } from "../../../core/utils/globalFunctions";
import { ListsService } from "../../../core/api/lists";
import { TList } from "../../../core/models/lists";
import theme from "../../../core/theme/theme";
import "./animations.css";
import { TSaleBody, TSaleFilterRequest, TSaleStatusType } from "../../../core/models/sale";
import { SaleService } from "core/api/sale/saleService";
import DefaultDialog from "app/components/defaultDialog/defaultDialog";
import { Order } from "../../../core/models/table";
import DataTablePagination from "../../components/table/pagination/pagination";
import Spinner from "../../components/spinner/spinner";
import FilterListIcon from "@mui/icons-material/FilterList";
import Search from "../../components/search/Search";
import DefaultChipList from "app/components/chip/defaultChipList";
import TableHeader from "app/components/table/tableHeader/TableHeader";
import DefaultFilter, { ISelectItem } from "app/components/filter/defaultFilter";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
interface IManageListModalProps {
    list: TList;
    isOpen: boolean;
    onClose: () => void;
    onOpen: () => void;
    refresh: () => void;
}

const ManageListModal = ({
    list,
    isOpen,
    onClose,
    onOpen,
    refresh
}: IManageListModalProps) => {
    const [addClient, setAddClient] = useState(false);
    const [search, setSearch] = React.useState<string>("");
    const [options, setOptions] = React.useState<readonly TSaleBody[]>([]);
    const [selectedValue, setSelectedValue] = React.useState<TSaleBody>();
    const [open, setOpen] = React.useState(false);
    const [confirmAdd, setConfirmAdd] = useState(false);
    const [saveList, setSaveList] = useState(false);
    const [filterModal, setFilterModal] = useState(false);
    const [dowloadDialog, setDowloadDialog] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);
    const [order, setOrder] = useState<Order>("asc");
    const [orderBy, setOrderBy] = useState("name");

    const items: ISelectItem[] = [
        { name: "Cpf cliente", value: "clientCpforCnpj", type: "texto" },
        { name: "Nome do cliente", value: "nameClient", type: "texto" },
    ];

    const [saleFilter, setSaleFilter] = useState<TSaleFilterRequest>({
        status: undefined,
        cameThrough: undefined,
        typePayment: undefined,
        sellerCpfOrCnpj: undefined,
        clientCpforCnpj: "",
        indicationCpforCnpj: undefined,
        createDate: undefined,
        sellerId: undefined,
        typeSales: undefined,
        dateEnd: undefined,
        dateStart: undefined,
        nameClient: undefined
    });

    // ? pegar clientes da lista
    const listItems = useQuery({
        queryKey: [list.id, page, rowsPerPage, orderBy, order, saleFilter],
        queryFn: async () => await ListsService.getFilteredItems({
            page: page,
            size: rowsPerPage,
            sort: orderBy + "," + order,
            listId: list.id,
            clientCpforCnpj: saleFilter.clientCpforCnpj,
            nameClient: saleFilter.nameClient
        }),
        staleTime: Infinity,
    });

    // ? pegar vendas
    const listSalesByName = useQuery({
        queryKey: ["salesByName", search],
        queryFn: async () =>
            await SaleService.getFilteredSales({
                page: 0,
                size: 5,
                sort: "clientCpforCnpj,desc",
                status: undefined,
                clientCpforCnpj: search,
                cameThrough: undefined,
                createDate: undefined,
                typePayment: undefined,
                sellerCpfOrCnpj: undefined,
                indicationCpforCnpj: undefined,
                sellerId: undefined,
                dateEnd: undefined,
                dateStart: undefined,
                typeSales: "LIMPA_NOME"
            }),
    });

    const removeFilter = (attribute: string) => {
        setSaleFilter((prevState) => ({
            ...prevState,
            [attribute]: undefined,
        }));
    };


    const dowloadTabletWithFilters = React.useCallback(() => {
        setDowloadDialog(true);
        const payload = {
            listId: list.id,
            clientCpforCnpj: saleFilter.clientCpforCnpj,
            nameClient: saleFilter.nameClient
        };
        ListsService.getItemsCsv(payload).then((response) => {
            downloadCSVFromBase64(response, "tabelaFiltrada");
            setDowloadDialog(false);
        }).catch((err) => {
            setDowloadDialog(false);
            console.log(err);
        });
    }, [saleFilter, list.id, isOpen]);

    useEffect(() => {
        console.log(list.id);
        
    }, [list]);


    const verifyItems = () => {
        if (listItems.data && listItems.data.content.length === 0) {
            return false;
        }
        return true;
    }

    useEffect(() => {
        verifyItems()
    }, [saleFilter]);

    const loading = open && options.length === 0;

    // Debounce para evitar muitas requisições enquanto o usuário digita
    const debouncedSearch = useMemo(() => debounce(setSearch, 500), []);

    // ? fechar lista
    const handleCloseList = async () => {
        await ListsService.approveList();
        listItems.refetch();
        setSaveList(false);
        setSelectedValue(undefined);
        setAddClient(false);
        onClose();
        refresh();
    };

    // ? adicionar cliente atual na lista
    const handleAddClient = async () => {
        console.log("adicionando cliente");
        await ListsService.addItemList({
            listId: list.id,
            saleId: selectedValue?.id!,
        });
        console.log("operação finalizada");
        listItems.refetch();
        setAddClient(false);
        setSelectedValue(undefined);
        setConfirmAdd(false);
    };

    React.useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            await listSalesByName.refetch(); // For demo purposes.

            if (active) {
                setOptions(listSalesByName.data?.content ?? []);
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    useEffect(() => {
        if (listSalesByName.data) {
            setOptions(listSalesByName.data.content);
        }
    }, [listSalesByName.data]);


    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    useEffect(() => {
        if (listItems.isSuccess && listItems.data) {
            setCount(listItems.data.totalElements);
        }
    }, [listItems.isSuccess, listItems.data]);

    // ? limpa filtros e fecha modal
    const handleCloseModal = () => {
        onClose();
        setSaleFilter(
            {
                status: undefined,
                cameThrough: undefined,
                typePayment: undefined,
                sellerCpfOrCnpj: undefined,
                clientCpforCnpj: "",
                indicationCpforCnpj: undefined,
                createDate: undefined,
                sellerId: undefined,
                typeSales: undefined,
                dateEnd: undefined,
                dateStart: undefined,
                nameClient: undefined
            }
        );
    };

    return (
        <DefaultModal title={""} isOpen={isOpen} onOpen={onOpen} onClose={() => handleCloseModal()}>
            <Container>
                <Title>Listagem de clientes</Title>

                <Actions>
                    <ListInfos>
                        <Row>{list.name} - </Row>
                        <Row>{formatDateBr(list.openDate)} -</Row>
                        <Row>{list.status}</Row>
                    </ListInfos>
                    <Tooltip
                        title={"Adicionar cliente"}
                        placement={"top"}
                        sx={{
                            position: "absolute",
                            top: -8,
                            right: -5,
                        }}
                    >
                        <IconButton disabled={addClient || list.status !== "ABERTO"} onClick={() => setAddClient(true)}>
                            <PersonAddAlt1Icon
                                style={{
                                    color: (addClient || list.status) !== "ABERTO" ? theme.COLORS.GRAY4 : theme.COLORS.BLUE3,
                                }}
                            />
                        </IconButton>
                    </Tooltip>
                </Actions>

                <Divider />

                {/*Adicionar cliente*/}
                <CSSTransition
                    in={addClient}
                    timeout={300}
                    classNames="fade"
                    unmountOnExit
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 1,
                            marginTop: 2,
                            alignItems: "center",
                        }}
                    >
                        <Autocomplete
                            fullWidth
                            open={open}
                            onOpen={() => {
                                setOpen(true);
                            }}
                            onClose={() => {
                                setOpen(false);
                            }}
                            value={selectedValue}
                            onChange={(event: any, value: TSaleBody | null) => {
                                if (value) {
                                    setSelectedValue(value);
                                    setSearch(value.client.cpforcnpj);
                                }
                            }}
                            isOptionEqualToValue={(option, value) =>
                                option.client.cpforcnpj === value.client.cpforcnpj
                            }
                            getOptionLabel={(option: TSaleBody) =>
                                `${option.client.name} - ${option.client.cpforcnpj}`
                            }
                            renderOption={(props, option) => (
                                <li {...props}>
                                    {option.client.name} - {option.client.cpforcnpj}
                                </li>
                            )}
                            options={options}
                            loading={listSalesByName.isLoading}
                            loadingText={"Procurando..."}
                            noOptionsText={"Nenhum cliente encontrado"}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    size={"small"}
                                    fullWidth
                                    label={"Pesquisar venda por CPF/CNPJ"}
                                    variant={"standard"}
                                    onChange={(e) => debouncedSearch(e.target.value)}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {listSalesByName.isLoading ? (
                                                    <CircularProgress color="inherit" size={20} />
                                                ) : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        />
                        <Tooltip title={"Cancelar"}>
                            <IconButton
                                sx={{ width: 30, height: 30 }}
                                onClick={() => {
                                    setAddClient(false);
                                    setSelectedValue(undefined);
                                    setSearch("");
                                }}
                            >
                                <CloseIcon style={{ color: theme.COLORS.RED }} />
                            </IconButton>
                        </Tooltip>
                        {selectedValue && (
                            <Tooltip title={"Adicionar"}>
                                <IconButton
                                    sx={{ width: 30, height: 30 }}
                                    onClick={() => setConfirmAdd(true)}
                                >
                                    <SaveIcon style={{ color: theme.COLORS.BLUE3 }} />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Box>
                </CSSTransition>
                {
                    listItems.isLoading ? (
                        <Box sx={{ position: "relative", height: 400 }}>
                            <Spinner
                                state={listItems.isLoading}
                                size={10}
                                css={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                }}
                            />
                        </Box>
                    ) : (
                        <>
                            <TableHeader
                                filterBtn
                                filterBtnAction={() => setFilterModal(true)}
                                filter={saleFilter}
                                remove={removeFilter}
                                mainActionLabel="Baixar tabela"
                                mainActionDisabled={dowloadDialog || !verifyItems()}
                                mainActionFunction={() => dowloadTabletWithFilters()}
                                mainIcon={
                                    <FileDownloadIcon
                                        sx={{
                                            fontSize: "20px",
                                            color: dowloadDialog || !verifyItems() ? theme.COLORS.GRAY4 : theme.COLORS.BLUE3,
                                        }}
                                    />
                                }
                                extraComponents={
                                    <Search
                                        searchPlaceHolder="Cpf/cnpj do cliente..."
                                        cpfClient={saleFilter.clientCpforCnpj}
                                        querrySearching={listItems.isLoading}
                                        onChange={(e: string) => setSaleFilter((prevState) => ({
                                            ...prevState,
                                            clientCpforCnpj: e,
                                        }))}
                                    />
                                }
                            />
                            <DisplayList
                                listStatus={list.status}
                                refresh={listItems.refetch}
                                list={listItems.data?.content}
                                refeshList={refresh}
                            />
                        </>
                    )
                }
                <DataTablePagination
                    setPage={setPage}
                    page={page}
                    setRowsPerPage={setRowsPerPage}
                    rowsPerPage={rowsPerPage}
                    count={count}
                />
                <Button onClick={() => setSaveList(true)} disabled={list.status !== "ABERTO"}>Fechar lista</Button>
            </Container>

            <DefaultDialog
                title="Alterar lista"
                isOpen={confirmAdd}
                onCloseAction={() => setConfirmAdd(false)}
                confirmAction={handleAddClient}
                body={
                    <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                        O cliente{" "}
                        <Typography fontWeight={"bold"}>
                            {selectedValue?.client.name}
                        </Typography>{" "}
                        será adicionado na lista.
                    </Box>
                }
            />
            <DefaultFilter
                isOpen={filterModal}
                items={items}
                onChangeFilter={setSaleFilter}
                onClose={() => setFilterModal(false)}
                onOpen={() => setFilterModal(true)}
                title="Filtrar cliente"
                changePage={setPage}
            />

            <DefaultDialog
                title="Fechar lista?"
                isOpen={saveList}
                onCloseAction={() => setSaveList(false)}
                confirmAction={handleCloseList}
            />
        </DefaultModal>
    );
};

export default ManageListModal;

const Actions = styled(Box)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
`;

const Container = styled.div`
    margin: auto;
    min-width: 500px;
    background-color: white;
    border-radius: 5px;
    border: 1px solid #f3f3f3;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    padding: 1em;
    transition: all 300ms ease-in-out;
`;

const ListInfos = styled(Box)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1;
`;

const Title = styled(Typography)`
    font-size: 1.9pc !important;
    font-weight: bold !important;
    color: ${({ theme }) => theme.COLORS.BLUE3} !important;
    text-align: center !important;
`;

const Row = styled(Typography)`
    font-weight: bold !important;
`;
