import { Box, IconButton, InputAdornment, InputProps, TextFieldProps, Typography, useMediaQuery, useTheme } from '@mui/material';
import { IFormField } from 'app/components/sale/clientBlockForm';
import { useSaleFormContext } from 'core/context/SaleContext';
import { useState } from 'react'
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import styled from 'styled-components';
import theme from 'core/theme/theme';
import GenericTextField from 'app/components/genericTextField/GenericTextField';

/* 
    Este componente é um formulário para o cliente criar sua senha
    Caso o cliente já seja registrado ele nem verá essa tela
*/

const ClientPassword = () => {

    const { formik } = useSaleFormContext();

    const materialTheme = useTheme();
    const isSmallScreen = useMediaQuery(materialTheme.breakpoints.down("sm"));

    const [showPassword, setShowPassword] = useState(false);

    const loginInfos: IFormField[] = [
        { name: "password", label: "Senha" },
        { name: "confirmPassword", label: "Confirmar senha" },
    ];

    function togglePassword() {
        setShowPassword(!showPassword);
    }

    let passwordFieldProps: (InputProps & TextFieldProps) | undefined = {
        type: showPassword ? "text" : "password",
        onChange: formik.handleChange,
        InputProps: {
            endAdornment: (
                <InputAdornment position="end">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={togglePassword}
                        edge="end"
                    >
                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                </InputAdornment>
            ),
        },
    };

    return (
        <StyledContainer mobile={isSmallScreen}>
            <ColumnContainer>
                <ColumnTitle>Hora de definir sua senha</ColumnTitle>
                {loginInfos.map((field) => (
                    <GenericTextField<string>
                        key={field.name}
                        error={
                            !!(formik.errors as Record<string, string | undefined>)[
                            field.name
                            ]
                        }
                        helperText={
                            (formik.errors as Record<string, string | undefined>)[field.name]
                        }
                        name={field.name}
                        label={field.label}
                        value={formik.values[field.name]}
                        props={passwordFieldProps}
                    />
                ))}
            </ColumnContainer>
        </StyledContainer>
    )
}

export default ClientPassword


const StyledContainer = styled(Box) <{ mobile?: boolean }>`
  display: flex;
  gap: 1rem;

  ${({ mobile }) =>
        mobile
            ? `
        flex: 1;
        flex-direction: column;
      `
            : `
        flex: 0.2;
        flex-direction: row;
        margin: auto;
  `}
`;

const ColumnContainer = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ColumnTitle = styled(Typography)`
  color: ${theme.COLORS.BLUE3} !important;
  font-size: 18 !important;
  font-weight: bold !important;
  text-align: center !important;
`;
