import { useMemo } from "react";

import { formatCurrencyBR } from "core/utils/globalFunctions";
import { TCouponResponse } from "core/models/coupons";

export const usePaymentOptions = ({
  cleanDocument,
  cupom,
  urlType,
  handleChangeFees,
  handleChangePaymentType,
}: {
  cleanDocument: string;
  cupom: TCouponResponse;
  urlType: string[];
  handleChangeFees: (isFees: boolean) => void;
  handleChangePaymentType: (type: string) => void;
}) => {
  return useMemo(() => {
    const options = [];

    // CPF LIMPA NOME - Sem Cupom
    if (
      cleanDocument.length === 11 &&
      !cupom?.nameCoupon &&
      urlType[3] !== "consultoria"
    ) {
      options.push(
        {
          label: "1x R$ 1.500,00 - Pix",
          value: 0,
          onClick: () => {
            handleChangeFees(false);
            handleChangePaymentType("PIX");
          },
        },
        {
          label: "1 + 1 R$ 800,00 - Pix + Boleto",
          value: 2,
          onClick: () => {
            handleChangeFees(true);
            handleChangePaymentType("BOLETO");
          },
        }
      );
    }

    // CPF LIMPA NOME - Com Cupom
    if (cleanDocument.length === 11 && cupom?.valuePixCpf) {
      options.push({
        label: `1x ${formatCurrencyBR(Number(cupom.valuePixCpf))} - Pix`,
        value: 0,
        onClick: () => {
          handleChangeFees(false);
          handleChangePaymentType("PIX");
        },
      });
    }

    if (cleanDocument.length === 11 && cupom?.valueInstallmentCpf) {
      options.push({
        label: `2x ${formatCurrencyBR(
          Number(cupom.valueInstallmentCpf)
        )} - Pix + Boleto`,
        value: 2,
        onClick: () => {
          handleChangeFees(true);
          handleChangePaymentType("BOLETO");
        },
      });
    }

    // CNPJ LIMPA NOME - Sem Cupom
    if (cleanDocument.length === 14 && !cupom?.nameCoupon) {
      options.push(
        {
          label: "1x R$ 2.000,00 - Pix",
          value: 0,
          onClick: () => {
            handleChangeFees(false);
            handleChangePaymentType("PIX");
          },
        },
        {
          label: "2x R$ 1.050,00 - Boleto",
          value: 2,
          onClick: () => {
            handleChangeFees(true);
            handleChangePaymentType("BOLETO");
          },
        }
      );
    }

    // CNPJ LIMPA NOME - Com Cupom
    if (cleanDocument.length === 14 && cupom?.valuePixCnpj) {
      options.push({
        label: `1x ${formatCurrencyBR(Number(cupom.valuePixCnpj))} - Pix`,
        value: 0,
        onClick: () => {
          handleChangeFees(false);
          handleChangePaymentType("PIX");
        },
      });
    }

    if (cleanDocument.length === 14 && cupom?.valueInstallmentCnpj) {
      options.push({
        label: `2x ${formatCurrencyBR(
          Number(cupom.valueInstallmentCnpj)
        )} - Pix + Boleto`,
        value: 2,
        onClick: () => {
          handleChangeFees(true);
          handleChangePaymentType("BOLETO");
        },
      });
    }

    // CONSULTORIA
    if (urlType[3] === "consultoria") {
      options.push({
        label: "1x R$ 297,00 - Pix",
        value: 0,
        onClick: () => {
          handleChangeFees(false);
          handleChangePaymentType("PIX");
        },
      });
    }

    return options;
  }, [
    cleanDocument,
    cupom,
    urlType,
    handleChangeFees,
    handleChangePaymentType,
  ]);
};
