import React, { useState } from "react";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import {
  Avatar,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import HailIcon from "@mui/icons-material/Hail";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import ReceiptIcon from "@mui/icons-material/Receipt";
import DiscountIcon from "@mui/icons-material/Discount";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import CampaignIcon from "@mui/icons-material/Campaign";
import PaymentsIcon from "@mui/icons-material/Payments";
import LinkIcon from "@mui/icons-material/Link";
import GroupIcon from "@mui/icons-material/Group";
import ArticleIcon from "@mui/icons-material/Article";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { Link, useLocation, useNavigate } from "react-router-dom";

import customTheme from "core/theme/theme";
import { useAppDispatch, useAppSelector } from "core/hooks/reduxHooks";
import { logout } from "core/redux/slices/authSlice";
import LogoMax from "images/assets/logo2.png";
import { IconList } from "../sideBar/styles";
import SideBarItem from "../sideBarItem/sideBarItem";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme }) => ({
  backgroundColor: "#fff",
  boxShadow: "none",
  borderBottom: `1px solid ${theme.palette.divider}`,
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  variants: [
    {
      props: ({ open }) => open,
      style: {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
      },
    },
    {
      props: ({ open }) => !open,
      style: {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
      },
    },
  ],
}));

interface NavbarProps {
  children: React.ReactNode;
}

type Anchor = "top" | "left" | "bottom" | "right";

const Navbar: React.FC<NavbarProps> = ({ children }) => {
  const theme = useTheme();
  const location = useLocation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const basicUserInfo = useAppSelector((state) => state.auth.userInfo);

  const [open, setOpen] = useState(false);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [state, setState] = React.useState({
    top: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  let urlPart = location.pathname.split("/").pop();

  if (urlPart) {
    urlPart = urlPart.charAt(0).toUpperCase() + urlPart.slice(1);
  } else {
    urlPart = "Home";
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenuAction = async (action: "logout" | "editUser") => {
    setAnchorElUser(null);
    switch (action) {
      case "logout":
        await dispatch(logout()).then(() => {
          navigate("/login");
        });
        break;
      case "editUser":
        navigate("/editarUsuario");
        break;
      default:
        break;
    }
  };

  const materialTheme = useTheme();
  const isSmallScreen = useMediaQuery(materialTheme.breakpoints.down("md"));

  return (
    <Box sx={{ display: "flex", overflowY: "scroll" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar sx={{ position: "relative", justifyContent: "space-between" }}>
          {!isSmallScreen && (
            <IconButton
              onClick={
                isSmallScreen
                  ? () => toggleDrawer("top", true)
                  : handleDrawerOpen
              }
              edge="start"
              sx={[
                {
                  marginRight: 5,
                },
                open && { display: "none" },
              ]}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Box></Box>
          {/* Elemento para centralizar o título */}
          <Typography
            sx={{
              position: "absolute",
              left: "5%",
              color: customTheme.COLORS.BLUE3,
              fontWeight: "bold",
              fontSize: 18,
              textAlign: "center",
            }}
          >
            {urlPart}
          </Typography>
          {!isSmallScreen && <LogoSidebarMax />}
          <UserMenu>
            <Typography
              sx={{
                color: customTheme.COLORS.BLUE3,
                fontWeight: "bold",
                fontSize: 18,
              }}
            >
              {basicUserInfo?.name}
            </Typography>
            <Tooltip title="Opções">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  sx={{
                    bgcolor: customTheme.COLORS.BLUE3,
                    color: customTheme.COLORS.WHITE,
                  }}
                >
                  {basicUserInfo?.name?.charAt(0).toUpperCase() ?? "?"}
                </Avatar>
              </IconButton>
            </Tooltip>
          </UserMenu>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={() => setAnchorElUser(null)}
          >
            <MenuItem onClick={() => handleMenuAction("editUser")}>
              <Typography alignItems={"center"}>Perfil</Typography>
            </MenuItem>
            <MenuItem onClick={() => handleMenuAction("logout")}>
              <Typography alignItems={"center"}>Sair</Typography>
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader sx={{ display: "flex", justifyContent: "space-around" }}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>

        <IconList $isOpen={open}>
          <SideBarItem
            isOpen={open}
            onClose={() => {}}
            icon={DashboardIcon}
            link="/dashboard"
            label="Dashboard"
            notAcceptGroup="CLIENT"
            acepptRoles={["ROLE_ADMIN"]}
          />
          <SideBarItem
            isOpen={open}
            onClose={() => {}}
            icon={LinkIcon}
            link="/links"
            label="Links"
            acepptRoles={[
              "ROLE_ADMIN",
              "ROLE_SELLER",
              "ROLE_CRUD_SELLER",
              "ROLE_FINANCEIRO",
            ]}
            notAcceptGroup="INDICATION"
          />
          <SideBarItem
            isOpen={open}
            onClose={() => {}}
            icon={MonetizationOnIcon}
            link="/financeiro"
            label="Financeiro"
            acepptRoles={["ROLE_ADMIN"]}
            notAcceptGroup="CLIENT"
          />
          <SideBarItem
            isOpen={open}
            onClose={() => {}}
            icon={FormatListBulletedIcon}
            link="/listas"
            label="Listas"
            acepptRoles={["ROLE_ADMIN"]}
            notAcceptGroup="CLIENT"
          />
          <SideBarItem
            isOpen={open}
            onClose={() => {}}
            icon={CampaignIcon}
            link="/campanhas"
            label="Campanhas"
            acepptRoles={["ROLE_ADMIN"]}
            notAcceptGroup="CLIENT"
          />
          <SideBarItem
            isOpen={open}
            onClose={() => {}}
            icon={ReceiptIcon}
            link="/vendas"
            label="Vendas"
            notAcceptGroup="CLIENT"
            acepptRoles={[
              "ROLE_ADMIN",
              "ROLE_SELLER",
              "ROLE_INDICATIONS",
              "ROLE_CRUD_SELLER",
              "ROLE_CRUD_INDICATIONS",
              "ROLE_FINANCEIRO",
            ]}
          />
          <Divider flexItem />
          <SideBarItem
            isOpen={open}
            onClose={() => {}}
            icon={HailIcon}
            link="/vendedores"
            label="Vendedores"
            acepptRoles={["ROLE_ADMIN", "ROLE_CRUD_SELLER"]}
          />
          <SideBarItem
            isOpen={open}
            onClose={() => {}}
            icon={GroupIcon}
            link="/clientes"
            label="Clientes"
            acepptRoles={["ROLE_ADMIN"]}
          />
          <SideBarItem
            isOpen={open}
            onClose={() => {}}
            icon={Diversity3Icon}
            link="/indicacoes"
            label="Indicacoes"
            acepptRoles={[
              "ROLE_ADMIN",
              "ROLE_INDICATIONS",
              "ROLE_CRUD_INDICATIONS",
            ]}
          />
          <SideBarItem
            isOpen={open}
            icon={DiscountIcon}
            link="/cupons"
            label="Cupons"
            acepptRoles={["ROLE_ADMIN", "ROLE_CRUD_SELLER", "ROLE_SELLER"]}
            notAcceptGroup="INDICATION"
          />

          {basicUserInfo?.group !== "CLIENT" && <Divider flexItem />}
          {basicUserInfo?.group === "CLIENT" && (
            <>
              <SideBarItem
                isOpen={open}
                icon={PaymentsIcon}
                link="/pagamentos"
                label="Pagamentos"
                acepptRoles={["ROLE_COURSES"]}
                notAcceptGroup="ROLE_ADMIN"
              />
              <SideBarItem
                isOpen={open}
                icon={ArticleIcon}
                link="/contrato"
                label="Contrato"
                acepptRoles={["ROLE_COURSES"]}
                notAcceptGroup="ROLE_ADMIN"
              />
            </>
          )}
          <SideBarItem
            isOpen={open}
            icon={OndemandVideoIcon}
            link="/aulas"
            label="Aulas"
            acepptRoles={["ROLE_ADMIN", "ROLE_COURSES", "ROLE_CRUD_COURSES"]}
          />
        </IconList>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1 }}>
        <Toolbar sx={{ height: "100px" }} />
        {children}
      </Box>
    </Box>
  );
};
export default Navbar;

const LogoSidebarMax = styled(Box)`
  background-image: url(${LogoMax});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100px;
  height: 50px;
  overflow: hidden;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

const UserMenu = styled(Box)`
  display: flex;
  align-items: center;
  gap: 0.5em;
`;
