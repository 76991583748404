import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import { Navigate, Outlet } from "react-router-dom";

import theme from "theme";
import Footer from "app/components/footer/Footer";
import Navbar from "app/components/navbar/navbar";
import DefaultDialog from "app/components/defaultDialog/defaultDialog";
import { useAppDispatch } from "core/hooks/reduxHooks";
import {
  ITeste,
  fetchUserName,
  getUserFromToken,
  logout,
} from "core/redux/slices/authSlice";
import useExpiredLoginHook from "core/hooks/expiredLogin/expiredLoginHook";
import { useState } from "react";

const ProtectedRoute = () => {
  const dispatch = useAppDispatch();
  const basicUserInfo = getUserFromToken();

  const { isOpen, onClose } = useExpiredLoginHook();

  const [hasFetchedUserName, setHasFetchedUserName] = useState(false);

  if (!basicUserInfo?.name && !hasFetchedUserName) {
    setHasFetchedUserName(true); 
    const teste: ITeste = {
      id: basicUserInfo?.id ?? 0,
      type: basicUserInfo?.group ?? "",
    };
    dispatch(fetchUserName(teste));
  }

  if (localStorage.getItem("token") === null) {
    return <Navigate replace to={"/login"} />;
  }

  return (
    <AppContainer>
      <Navbar>
        <Outlet />
        <Footer />
      </Navbar>

      <DefaultDialog
        title="Sessão expirada"
        isOpen={isOpen}
        confirmAction={() => dispatch(logout())}
        onCloseAction={onClose}
        body={<Typography textAlign={"center"}>deslogar?</Typography>}
      />
    </AppContainer>
  );
};

export default ProtectedRoute;

const AppContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
`;

const Content = styled(Box)<{ $isLoginPage?: boolean }>`
  background: ${(props) =>
    props.$isLoginPage ? theme.palette.background.default : "#fff"};
`;
