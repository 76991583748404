import {
  FINANCIAL_PIX,
  FINANCIAL_BOLETO,
  ALL_SALES_INFOS_BY_RANGE,
} from "core/utils/constants";
import { axiosInstance } from "../axios/axiosInstance";
import { IPage, IResponseBody } from "core/models/utils";
import {
  TFinancialBoletoPageable,
  TFinancialPixPageable,
} from "core/models/financial";
import { TPixInvoiceSales } from "core/models/financial/pixInvoiceSales";

const getFilteredFinancialPix = async (
  financialPageable: TFinancialPixPageable
): Promise<IPage<any> | undefined> => {
  const response = await axiosInstance.get<IResponseBody<IPage<any>>>(
    FINANCIAL_PIX,
    {
      params: {
        page: financialPageable.page,
        size: financialPageable.size,
        sort: "createdAt,desc",
        status: financialPageable?.status,
        clientcpforcnpj: financialPageable?.clientcpforcnpj,
        dateStart: financialPageable?.dateStart,
        dateEnd: financialPageable?.dateEnd,
      },
    }
  );
  return response.data.data;
};

const getAllSalesInfosByRange = async ({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}): Promise<TPixInvoiceSales | undefined> => {
  const response = await axiosInstance.get<IResponseBody<TPixInvoiceSales>>(
    ALL_SALES_INFOS_BY_RANGE,
    {
      params: {
        startDate,
        endDate,
      },
    }
  );
  return response.data.data;
};

const getFilteredFinancialBoleto = async (
  financialPageable: TFinancialBoletoPageable
): Promise<IPage<any> | undefined> => {
  const response = await axiosInstance.get<IResponseBody<IPage<any>>>(
    FINANCIAL_BOLETO,
    {
      params: {
        page: financialPageable.page,
        size: financialPageable.size,
        sort: "createdAt,desc",
        status: financialPageable?.status,
        clientcpforcnpj: financialPageable?.clientcpforcnpj,
        dateStart: financialPageable?.dateStart,
        dateEnd: financialPageable?.dateEnd,
      },
    }
  );
  return response.data.data;
};

export const FinancialService = {
  getFilteredFinancialPix,
  getFilteredFinancialBoleto,
  getAllSalesInfosByRange,
};
