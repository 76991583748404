import { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { TCourse } from "core/models/course";
import { fetchCourses } from "core/querryes/course/getCourseQuerry";
import useCourseHook from "core/hooks/courseHook";
import { useAppSelector } from "core/hooks/reduxHooks";
import { verifyRole } from "core/utils/roles";
import {
  ContentHeader,
  ContentTitle,
  PageContentContainer,
} from "app/components/styles";
import SchoolIcon from "@mui/icons-material/School";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import Card from "app/components/cards/defaultCard";
import { capitalizeFirstLetter } from "core/utils/globalFunctions";
import CourseRegisterModal from "app/components/modals/course/registerCourse/courseRegisterModal";
import { ContentBody } from "./styles";

const Classes = () => {
  const basicUserInfo = useAppSelector((state) => state.auth.userInfo);
  const navigate = useNavigate();
  const { onOpen } = useCourseHook();
  const [courses, setCourses] = useState<TCourse[]>([]);

  const { isSuccess, data } = useQuery({
    queryKey: ["courses", courses],
    staleTime: 16000,
    queryFn: () => fetchCourses(),
  });

  useEffect(() => {
    if (isSuccess && data) {
      setCourses(data.content);
    }
  }, [courses, data, isSuccess]);

  if (!verifyRole(basicUserInfo?.roles, ["ROLE_ADMIN", "ROLE_COURSES"])) {
    navigate(-1);
  }

  const handleCardClick = (course: TCourse) => {
    navigate("/cursoVideos", { state: { course } });
  };

  return (
    <PageContentContainer>
      <ContentHeader>
        <ContentTitle>Cursos</ContentTitle>
        {verifyRole(basicUserInfo?.roles, [
          "ROLE_ADMIN",
          "ROLE_CRUD_COURSES",
        ]) ? (
          <Button onClick={onOpen} variant={"contained"}>
            + Cadastrar
          </Button>
        ) : null}
      </ContentHeader>
      <ContentBody>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: 2,
            height: "100%",
            maxWidth: "90svw",
          }}
        >
          {data?.content.map(( video, index) => {
            // Calcula a duração total em minutos 
            const totalMinutes = video.videos.length * 2;
            return (
              <Card
                key={video.id}
                title={capitalizeFirstLetter(video.description)}
                hours={ index === 2 ? "4m" : `${totalMinutes}m`}
                icon={SchoolIcon}
                iconTime={AccessTimeFilledIcon}
                onClick={() => handleCardClick(video)}
              />
            );
          })}
        </Box>
        <CourseRegisterModal />
      </ContentBody>
    </PageContentContainer>
  );
};

export default Classes;
