import { Box, Button, Divider, Typography } from "@mui/material";
import { Icon } from "@mui/material";
import { IconType } from "react-icons";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material/SvgIcon";
import { BoxIcon, BoxInfo, ContainerCard, HoursAlign, TypographyCard } from "./styles";
import theme from "core/theme/theme";

interface ICardProps {
    title: string | undefined;
    icon: IconType | (OverridableComponent<SvgIconTypeMap> & { muiName: string });
    iconTime: IconType | (OverridableComponent<SvgIconTypeMap> & { muiName: string });
    onClick?: () => void;
    hours: string;
}

const Card = (props: ICardProps) => {
    const { title, icon, onClick, iconTime, hours } = props;

    return (
        <ContainerCard>
            <BoxInfo>
                <BoxIcon/>
                <TypographyCard sx={{ fontWeight: "bold"}}>
                    {title}
                </TypographyCard>
            </BoxInfo>
        <Divider style={{ width: '100%', margin: '0 auto' }} ></Divider>
            <HoursAlign>
                <Box sx={{ display: "flex", flexDirection: "row", marginLeft: "13px" }}>
                    <Icon component={iconTime} fontSize="small" color="action"/>
                    <Typography sx={{ fontWeight: "bold", color: "gray" }} >{hours}</Typography></Box>
                <Box>
                    <Button onClick={onClick} size="small" variant="text" sx={{fontSize: 11 }}>
                    Saiba mais
                    </Button>
                </Box>
            </HoursAlign>

        </ContainerCard>
    );
};

export default Card;
