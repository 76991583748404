import { useCallback } from "react";

import { useSaleFormContext } from "core/context/SaleContext";

/* 
    hook para validar a segunda etapa da venda
    - Validação das informações pessoais
    - Caso as informações estejam corretas, o usuário é redirecionado para a próxima etapa
*/

const useValidatePersonalInfos = () => {
  const { formik, setActiveStep, activeStep } = useSaleFormContext();

  const validatePersonalInfos = useCallback(async (): Promise<boolean> => {
    const errors = await formik.validateForm();
    const isValid =
      !errors.name &&
      !errors.email &&
      !errors.cpforcnpj &&
      !errors.telephone;

    if (isValid) {
      formik.setErrors({});
      setActiveStep(activeStep + 1);
      return true;
    }
    return false;
  }, [formik, setActiveStep, activeStep]);

  return { validatePersonalInfos };
};

export default useValidatePersonalInfos;
