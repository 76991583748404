import {
  Box,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import styled from "styled-components";

import { useSaleFormContext } from "core/context/SaleContext";
import GenericTextField from "app/components/genericTextField/GenericTextField";
import useCepData from "../hooks/useCepData";
import theme from "core/theme/theme";
import { IFormField } from "app/components/sale/clientBlockForm";

/* 
    Este componente é um formulário para os dados de endereço do cliente
    Caso o cliente já seja registrado ele nem verá essa tela
*/

const ClientAddressInfos = () => {
  const materialTheme = useTheme();
  const isSmallScreen = useMediaQuery(materialTheme.breakpoints.down("sm"));

  const { formik, isLoading } = useSaleFormContext();
  const { getCepData } = useCepData();

  const addressInfos: IFormField[] = [
    { name: "cep", label: "CEP" },
    { name: "city", label: "Cidade" },
    { name: "address", label: "Endereço" },
    { name: "neighborhood", label: "Bairro" },
  ];

  const states = [
    { value: "AC", label: "AC" },
    { value: "AL", label: "AL" },
    { value: "AP", label: "AP" },
    { value: "AM", label: "AM" },
    { value: "BA", label: "BA" },
    { value: "CE", label: "CE" },
    { value: "DF", label: "DF" },
    { value: "ES", label: "ES" },
    { value: "GO", label: "GO" },
    { value: "MA", label: "MA" },
    { value: "MT", label: "MT" },
    { value: "MS", label: "MS" },
    { value: "MG", label: "MG" },
    { value: "PA", label: "PA" },
    { value: "PB", label: "PB" },
    { value: "PR", label: "PR" },
    { value: "PE", label: "PE" },
    { value: "PI", label: "PI" },
    { value: "RJ", label: "RJ" },
    { value: "RN", label: "RN" },
    { value: "RS", label: "RS" },
    { value: "RO", label: "RO" },
    { value: "RR", label: "RR" },
    { value: "SC", label: "SC" },
    { value: "SP", label: "SP" },
    { value: "SE", label: "SE" },
    { value: "TO", label: "TO" },
  ];

  return (
    <StyledContainer mobile={isSmallScreen}>
      <ColumnContainer>
        <ColumnTitle>Informações de endereço</ColumnTitle>
        {addressInfos.map((field) => (
          <GenericTextField<string>
            key={field.name}
            error={
              !!(formik.errors as Record<string, string | undefined>)[
                field.name
              ]
            }
            helperText={
              (formik.errors as Record<string, string | undefined>)[field.name]
            }
            name={field.name}
            label={field.label}
            value={formik.values[field.name]}
            props={{
              disabled: isLoading,
              onChange: formik.handleChange,
              onBlur:
                field.name === "cep" ? (event) => getCepData(event) : undefined,
            }}
          />
        ))}
        <TextField
          size={isSmallScreen ? "medium" : "small"}
          fullWidth={isSmallScreen}
          variant={isSmallScreen ? "filled" : "outlined"}
          value={formik.values.uf}
          onChange={(e) => formik.setFieldValue("uf", e.target.value)}
          disabled={isLoading}
          id="outlined-select-state"
          margin="none"
          sx={{
            margin: Boolean(formik.errors.uf) ? 0 : 0.5,
          }}
          select
          label="Uf"
          name="uf"
          error={Boolean(formik.errors.uf)}
          helperText={formik.errors.uf}
          SelectProps={{
            MenuProps: {
              PaperProps: {
                style: {
                  maxHeight: 100,
                },
              },
            },
          }}
        >
          {states.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </ColumnContainer>
      {Object.keys(formik.errors).length > 0 && (
        <Typography variant="body2" color="error" sx={{ marginTop: 0.5 }}>
          Existem erros no formulário. Por favor, verifique os campos.
        </Typography>
      )}
    </StyledContainer>
  );
};

export default ClientAddressInfos;

const StyledContainer = styled(Box)<{ mobile?: boolean }>`
  display: flex;
  ${({ mobile }) =>
    mobile
      ? `
        flex: 1;
        overflow-y: auto;
        overflow-x: hidden;
      `
      : `
        flex: 0.25;
        flex-direction: row;
        margin: auto;
  `}
`;

const ColumnContainer = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ColumnTitle = styled(Typography)`
  color: ${theme.COLORS.BLUE3} !important;
  font-size: 18 !important;
  font-weight: bold !important;
`;
