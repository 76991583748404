import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Notification } from "app/components/toastNotification/toastNotification";
import { noAuthAxiosInstance } from "core/api/axios/axiosInstance";
import { AUTH } from "core/utils/constants";
import { jwtDecode } from "jwt-decode";
import { getQrCodeService, UserService } from "core/api/user/userService";
import {
  TUser,
  IUserPayload,
  AuthApiState,
  TLogin,
  TEdit,
} from "core/models/user";
import { useNavigate } from "react-router-dom";
import { removeNonNumeric } from "core/utils/globalFunctions";



export const getUserFromToken = (): TUser | null => {
  const token = localStorage.getItem("token");
  return translateToken(token);
};

// Função para traduzir o token JWT
const translateToken = (token: string | null): TUser | null => {
  if (!token) return null;
  try {
    const decoded: IUserPayload = jwtDecode(token);
    return {
      id: decoded.id,
      login: decoded.sub,
      roles: decoded.roles,
      group: decoded.group,
    };
  } catch {
    return null;
  }
};

const initialState: AuthApiState = {
  userInfo: translateToken(localStorage.getItem("token")),
  status: "idle",
  error: null,
};

export const login = createAsyncThunk("auth/login", async (data: TLogin) => {
  return await noAuthAxiosInstance
    .post(AUTH, data)
    .then((resp) => {
      const token = resp.data.data;
      localStorage.setItem("token", token);
      Notification("Login bem-sucedido", "success");
      window.location.pathname = "/dashboard";
      return token;
    })
    .catch((err: any) => {
      Notification(err.response?.data?.errors[0], "error");
      if (err.response?.data?.errors[0] === "Usuário desabilitado, Caso seja um cliente. Só será liberado o acesso a plataforma após a validação do pagamento do Pix ou Cartão de Credito.") {
        handleDisabledUserError(removeNonNumeric(data.login) ?? "");
      }
      throw new Error(err.response?.data?.errors[0]);
    });
});

// Função separada para lidar com usuários desabilitados
const handleDisabledUserError = async (login: string) => {
  try {
    const qrCodeResponse = await getQrCodeService(login);
     // Redirecionar para a nova página com os dados como query parameters
     const params = new URLSearchParams({
      qrCode: qrCodeResponse.data.data[0].brcode,
      idSale: qrCodeResponse.data.data[0].id,
    });
    console.log(qrCodeResponse.data.data[0]);
    window.location.href = `/pagar-pendencia?${params.toString()}`;
  } catch (qrError) {
    console.error("Erro ao obter QR Code:", qrError);
  }
};


export const updateUser = createAsyncThunk(
  "updateUser",
  async (data: TEdit) => {
    return await UserService.updateUser(data)
      .then((resp) => {
        return resp;
      })
      .catch((err: any) => {
        Notification(err.response?.data?.errors[0], "error");
        return err;
      });
  }
);

export type ITeste = {
  type: string;
  id: number;
};

export const fetchUserName = createAsyncThunk(
  "fetchUserName",
  async (data: ITeste) => {
    return await UserService.getUserName(data.type, data.id).then((resp) => {
      return resp.data;
    });
  }
);

export const logout = createAsyncThunk("auth/logout", () => {
  localStorage.removeItem("token");
  window.location.pathname = "/login";
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateUser(state, action: PayloadAction<TUser>) {},
    fetchUserName(state, action: PayloadAction<any>) {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action: PayloadAction<string>) => {
        state.status = "idle";
        state.userInfo = translateToken(action.payload);
      })
      .addCase(login.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Login failed";
      })
      .addCase(updateUser.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(
        updateUser.fulfilled,
        (state, action: PayloadAction<IUserPayload>) => {
          state.status = "idle";
          const roles = action.payload.roles?.map(
            (role: any) => "ROLE_" + role.name
          );
          state.userInfo = {
            ...action.payload,
            roles: roles,
          };
        }
      )
      .addCase(updateUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Update failed";
      })
      .addCase(fetchUserName.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = "idle";
        state.userInfo = {
          ...state.userInfo,
          name: action.payload.name || action.payload.coupon,
        };
        if (state.userInfo.group === "CLIENT") {
          state.client = action.payload;
        }
      });
  },
});

export default authSlice.reducer;
