import React, { useEffect, useState } from "react";
import {
    InputProps,
    TextField,
    TextFieldProps,
    FormHelperText,
    InputBaseComponentProps,
    InputAdornment,
} from "@mui/material";

type TDefaultTextField<T> = {
    name: string;
    label: string;
    value: string;
    props?: InputProps & TextFieldProps;
    small?: boolean;
    error?: boolean | undefined;
    helperText?: React.ReactNode;
    type?: React.HTMLInputTypeAttribute;
    onBlur?:
    | React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
    onChange?:
    | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
    style?: React.CSSProperties | undefined;
};

function PriceTextField<T>({
    name,
    label,
    value,
    props,
    helperText,
    error,
    type,
    onBlur,
    onChange,
    style,
}: TDefaultTextField<T>) {
    let numericValue = value.replace(/\D/g, "");
    return (
        <TextField
            helperText={
                error && (
                    <FormHelperText sx={{ margin: -0.5, padding: 0 }} error={error}>
                        {String(helperText)}
                    </FormHelperText>
                )
            }
            onChange={onChange}
            onBlur={onBlur}
            type={type ? type : undefined}
            size="small"
            variant="outlined"
            autoComplete="off"
            margin="none"
            id={name}
            label={label}
            value={numericValue}
            error={error}
            style={style}
            InputProps={{
                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
            }}
            {...props}
            sx={{
                margin: error ? 0 : 0.5,
            }}
        />
    );
}

export default PriceTextField;