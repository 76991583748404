import { useEffect, useMemo } from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import styled from "styled-components";

import theme from "core/theme/theme";
import { useSaleFormContext } from "core/context/SaleContext";
import useValidateDocument from "./hooks/useValidateDocument";
import useValidatePersonalInfos from "./hooks/useValidatePersonalInfos";
import useValidateAddressInfos from "./hooks/useValidadeAddress";
import useValidateCoupon from "./hooks/useValidateCupom";
import useGetCupomByLink from "./hooks/useGetCupomByLink";
import { useDynamicSteps } from "./stepperContent";
import { useSaleUrl } from "./hooks/useSaleUrl";
import useValidatePayment from "./hooks/useValidatePayment";
import useValidatePassword from "./hooks/useValidatePassword";

/*
    Aqui é recebido o passo atual, o tamanho total de passos e a função que muda o passo
    Dependendo do passo atual e do carregamento, os botões de voltar e avançar serão desabilitados

    Também é verificado qual função chamar dependendo do passo que o formulário se encontra
*/

interface NavigationFooterProps {
  stepsLength: number;
}

const NavigationFooter: React.FC<NavigationFooterProps> = ({ stepsLength }) => {
  const stepsConfig = useDynamicSteps();
  const {
    activeStep,
    setActiveStep,
    isLoading,
    userPermissions,
    isChecked,
    setPaymentInfo,
    paymentInfo,
    formik,
  } = useSaleFormContext();
  const { cupomId } = useSaleUrl();
  const { validateDocument } = useValidateDocument();
  const { validatePersonalInfos } = useValidatePersonalInfos();
  const { validatePassword } = useValidatePassword();
  const { validateAddressInfos } = useValidateAddressInfos();
  const { validateCoupon } = useValidateCoupon();
  const { validatePayment } = useValidatePayment();
  useGetCupomByLink();

  const materialTheme = useTheme();
  const isSmallScreen = useMediaQuery(materialTheme.breakpoints.down("sm"));

  const isLastStep = useMemo(
    () => activeStep === stepsLength - 2,
    [activeStep]
  );

  // Variável dinâmica para verificar se pode prosseguir
  const canGoNext = useMemo(() => {
    // if (activeStep === stepsLength - 1) return false;

    const currentStep = stepsConfig[activeStep];

    // Impede avançar do passo de cupom se o cupomId for nulo
    if (currentStep.label === "Contrato" && !isChecked) {
      return false;
    }

    // Impede de comprar sem método de pagamento
    if (
      currentStep.label === "Formas de pagamento" &&
      paymentInfo.typePayment === ""
    ) {
      return false;
    }

    if (currentStep.label === "Pagamento") {
      return false;
    }

    return true;
  }, [activeStep, stepsConfig, isChecked]);

  // Variável dinâmica para verificar se pode voltar
  const canGoBack = useMemo(() => {
    if (activeStep === 0) return false;

    const currentStep = stepsConfig[activeStep];
    const previousStep = stepsConfig[activeStep - 1];

    // Impede voltar para o índice 0 (CPF/CNPJ) após sair dele
    if (previousStep.label === "CPF/CNPJ" && currentStep.label !== "CPF/CNPJ") {
      return false;
    }

    // Impede voltar do contrato se cliente já existe
    if (
      currentStep.label === "Contrato" &&
      userPermissions?.isExistForClient &&
      cupomId !== 0 &&
      cupomId !== null
    ) {
      return false;
    }

    // Impede voltar do contrato se cliente já existe
    if (currentStep.label === "Cupom" && userPermissions?.isExistForClient) {
      return false;
    }

    if (currentStep.label === "Pagamento") {
      return false;
    }

    return true;
  }, [activeStep, stepsConfig, userPermissions]);

  // Função para avançar para o próximo passo é definida aqui
  const handleNext = async () => {
    const currentStep = stepsConfig[activeStep];
    if (activeStep < stepsLength - 1) {
      // Verifica se o passo atual é o de CPF/CNPJ
      if (currentStep.label === "CPF/CNPJ") {
        const canProceed = await validateDocument();
        if (!canProceed) return;
      }

      // Verifica se o passo atual é o de Informações pessoais
      if (currentStep.label === "Informações pessoais") {
        const canProceed = await validatePersonalInfos();
        if (!canProceed) return;
      }

      // Verifica se o passo atual é o de Senha
      if (currentStep.label === "Senha") {
        const canProceed = await validatePassword();
        if (!canProceed) return;
      }

      // Verifica se o passo atual é o de Endereço
      if (currentStep.label === "Endereço") {
        const canProceed = await validateAddressInfos();
        if (!canProceed) return;
      }

      // Verifica se o passo atual é o de validação do cupom
      if (currentStep.label === "Cupom") {
        const canProceed = await validateCoupon();
        if (!canProceed) return;
      }

      // Verifica se o passo atual é o de validação do cupom
      if (currentStep.label === "Contrato" && isChecked) {
        // resetar a forma de pagamento caso volte
        setPaymentInfo({
          typePayment: "",
          installments: undefined,
          isFees: false,
        });
        setActiveStep(activeStep + 1);
        return;
      }

      // Verifica se o passo atual é o de "Formas de pagamento" e realiza a venda
      if (currentStep.label === "Formas de pagamento") {
        await validatePayment();
      }
    }
  };

  // Função para voltar para o passo anterior é definida aqui
  // A função de voltar é bloquada em algumas situações
  const handleBack = () => {
    if (canGoBack) {
      setActiveStep(activeStep - 1);
    }
  };

  const nextButtonBackgroundColor = useMemo(() => {
    if (!canGoNext) return theme.COLORS.GRAY4;
    if (isLastStep) return "#569C00";
    return theme.COLORS.BLUE3;
  }, [canGoNext, isLastStep]);

  return (
    <FooterContainer mobile={isSmallScreen}>
      <NeubrutalButton onClick={handleBack} disabled={!canGoBack || isLoading}>
        Voltar
      </NeubrutalButton>
      <NeubrutalButton
        onClick={handleNext}
        disabled={isLoading || !canGoNext}
        style={{
          backgroundColor: nextButtonBackgroundColor,
          color: theme.COLORS.WHITE,
        }}
      >
        {isLastStep ? "Finalizar" : "Avançar"}
      </NeubrutalButton>
    </FooterContainer>
  );
};

export default NavigationFooter;

const FooterContainer = styled(Box)<{ mobile?: boolean }>`
  display: flex;
  padding: 1rem;
  justify-content: end;
  align-items: center;
  background: ${theme.COLORS.WHITE};
  gap: 0.5rem;

  ${({ mobile }) =>
    mobile
      ? `
        
      `
      : `
        border-top: 4px solid ${theme.COLORS.BLACK};
      `}
`;

const NeubrutalButton = styled.button`
  background: ${({ theme }) => theme.COLORS.WHITE};
  color: ${({ theme }) => theme.COLORS.BLACK};
  border: 3px solid ${({ theme }) => theme.COLORS.BLACK};
  border-radius: 8px;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease;

  /* Sombras fortes */
  box-shadow: 6px 6px 0 ${({ theme }) => theme.COLORS.BLACK};

  &:hover {
    transform: translateY(-2px);
    box-shadow: 8px 8px 0 ${({ theme }) => theme.COLORS.BLACK};
  }

  &:active {
    transform: translateY(2px);
    box-shadow: 4px 4px 0 ${({ theme }) => theme.COLORS.BLACK};
  }

  &:disabled {
    background: ${({ theme }) => theme.COLORS.GRAY4};
    color: ${({ theme }) => theme.COLORS.GRAY5};
    border: 3px solid ${({ theme }) => theme.COLORS.GRAY5};
    cursor: not-allowed;
    box-shadow: none;
  }
`;
