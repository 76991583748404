import { Box, IconButton, Tooltip } from "@mui/material";
import { formatCurrencyBR, formatDateBr } from "core/utils/globalFunctions";
import { ContentCopy } from "@mui/icons-material";
import copy from "clipboard-copy";
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import MoreHorizRounded from "@mui/icons-material/MoreHorizRounded";
import { TPixBodyResponse } from "core/models/payment/pix";
import theme from "core/theme/theme";
import { InfoColumn } from "./styles";
import { InfoKey, InfoValue } from "app/components/styles";
import PixIcon from "@mui/icons-material/Pix";
import { SaleService } from "core/api/sale/saleService";
import { useEffect, useState } from "react";
import DefaultMenu, { IMenuItemProps } from "app/components/menu/DefaultMenu";
import { Notification } from "app/components/toastNotification/toastNotification";
import { useAppSelector } from "core/hooks/reduxHooks";

interface IPixInfoProps {
  pix: TPixBodyResponse;
  isClient?: boolean;
  saleStatus?: string;
  reRender?: () => void;
  idSale?: number;
  idPayment?: number;
}

const PixInfo = (props: IPixInfoProps) => {
  const userInfo = useAppSelector((state) => state.auth.userInfo);
  const { pix, isClient, reRender, saleStatus, idSale, idPayment } = props;
  const { brcode, dateCreation, dueDate, status, value, paymentDate, id } = pix;
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);


  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleBaixaNoPagamento = async (id: number) => {
    setLoading(true)
    await SaleService.baixaNoPagamento(id)
      .then(() => {
        setLoading(false)
        if (reRender) {
          reRender()
        }
      })
  }

  const items: IMenuItemProps[] = [
    {
      function: () => {
        handleCloseMenu();
        handleAsync(id);
      },
      label: "Sincronizar",
    },
    ...(status === "VENCIDO" && userInfo?.group === "ADMIN"
      ? [
        {
          function: () => {
            if (idSale) {
              handleGeneratePix(idSale);
            }
            handleCloseMenu();
          },
          label: "Gerar novo pagamento",
        },
      ]
      : []),
    ...((saleStatus === "EM_PAGAMENTO" || saleStatus === "PENDENTE") && userInfo?.group === "ADMIN"
      ? [
        {
          function: () => {
            if (idPayment) {
              handleBaixaNoPagamento(idPayment);
            }
            handleCloseMenu();
          },
          label: "Dar baixa no pagamento",
        },
      ]
      : []),
  ];


  const handleAsync = async (id: number) => {
    setLoading(true)
    await SaleService.asyncPaymentById(id)
      .then(() => {
        setLoading(false)
        if (reRender) {
          reRender()
        }
      })
  }

  useEffect(() => {
    console.log("idSale", idSale)
  }, [idSale])


  const handleGeneratePix = async (id: number) => {
    setLoading(true)
    await SaleService.generateNewPix(id)
      .then(() => {
        setLoading(false)
        handleCloseMenu();
        if (reRender) {
          reRender()
        }
      })
  }

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        marginBottom: "-8px",
        justifyContent: "space-between",
        alignItems: "center",
        background: theme.COLORS.GRAY6,
        borderRadius: 1,
        padding: "0.3rem 0.5rem",
        borderLeft:
          status === "LIQUIDADO"
            ? `6px solid ${theme.COLORS.GREEN1}`
            : status === "VENCIDO"
              ? `6px solid ${theme.COLORS.RED}`
              : `6px solid ${theme.COLORS.YELLOW}`,
      }}
    >
      <PixIcon sx={{ fontSize: "30px", color: "#000" }} />
      <InfoColumn>
        <InfoKey>Emissão</InfoKey>
        <InfoValue>{formatDateBr(dateCreation)}</InfoValue>
      </InfoColumn>
      <InfoColumn>
        <InfoKey>Vencimento</InfoKey>
        <InfoValue>{formatDateBr(dueDate)}</InfoValue>
      </InfoColumn>
      <InfoColumn>
        <InfoKey>Pagamento</InfoKey>
        <InfoValue>{formatDateBr(paymentDate)}</InfoValue>
      </InfoColumn>
      <InfoColumn>
        <InfoKey>Valor</InfoKey>
        <InfoValue>{formatCurrencyBR(value)}</InfoValue>
      </InfoColumn>
      <InfoColumn>
        <InfoKey>Status</InfoKey>
        <InfoValue>{status}</InfoValue>
      </InfoColumn>
      <Box>
        <Tooltip title="Copiar código">
          <IconButton
            disabled={status === "LIQUIDADO"}
            onClick={() => copy(brcode)}
          >
            <ContentCopy
              sx={{
                fontSize: "30px",
                color:
                  status === "LIQUIDADO" ? theme.COLORS.GRAY4 : theme.COLORS.BLUE3,
              }}
            />
          </IconButton>
        </Tooltip>
        {((saleStatus === "PENDENTE" || status === "VENCIDO" || saleStatus === "EM_PAGAMENTO") && !isClient) && (
          <Tooltip title="Opções">
            <IconButton onClick={handleClickMenu}>
              <MoreHorizRounded />
            </IconButton>
          </Tooltip>
        )}
        <DefaultMenu
          anchor={anchorEl}
          menuItems={items}
          onClose={handleCloseMenu}
          status={open}
        />
        {/* {
          (!isClient && saleStatus === "PENDENTE") && (
            <IconButton
              disabled={loading}
              onClick={() => handleAsync(id)}
            >
              <CloudSyncIcon
                sx={{
                  fontSize: "30px",
                  color: loading ? theme.COLORS.GRAY4 : theme.COLORS.BLUE3,
                }}
              />
            </IconButton>)
        } */}
      </Box>
    </Box>
  );
};

export default PixInfo;
