import { useState } from "react";

export const useModalManager = () => {
  const [modalLinkIndicationConsultoria, setModalLinkIndicationConsultoria] =
    useState(false);
  const [modalLinkCupom, setModalLinkCupom] = useState(false);
  const [modalLinkCupomIndication, setModalLinkCupomIndication] =
    useState(false);

  return {
    modalLinkIndicationConsultoria,
    setModalLinkIndicationConsultoria,
    modalLinkCupom,
    setModalLinkCupom,
    modalLinkCupomIndication,
    setModalLinkCupomIndication,
  };
};
