import { ClientService } from "core/api/client/clientService";
import { Order } from "core/models/table";
import { removeNonNumeric } from "core/utils/globalFunctions";

export const fetchClients = async (
    page: number,
    rowsPerPage: number,
    orderBy: string,
    order: Order,
    name?: string,
    cpforCnpj?: string,
  ) => {
    return await ClientService.getClients({
      page: page,
      size: rowsPerPage,
      sort: orderBy + "," + order,
      name: name,
      cpforCnpj: cpforCnpj === "" ?  undefined : removeNonNumeric(cpforCnpj),
    });
  };
  