import {
  Box,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import DefaultFilter, {
  ISelectItem,
} from "app/components/filter/defaultFilter";
import { PageContentContainer } from "app/components/styles";
import DataTablePagination from "app/components/table/pagination/pagination";
import TableHeader from "app/components/table/tableHeader/TableHeader";
import { ContentBody } from "app/views/sellerList/styles";
import useFinancialFilterHook from "core/hooks/filters/financialHook";
import { ITableHeadCell } from "core/models/table";
import Search from "app/components/search/Search";
import { StyledDivDataTable } from "app/components/table/tableHead/styles";
import { SalesCell, SalesTypography } from "app/views/sales/styles";
import {
  formatarData,
  formatarDataSemHoras,
  formatCurrencyBR,
  formatDateBr,
  formatDocument,
} from "core/utils/globalFunctions";
import Spinner from "app/components/spinner/spinner";
import StyledStatus from "app/components/styledStatus/styledStatus";
import theme from "core/theme/theme";
import { useDateFilter } from "../../../../core/context/financialContext";
import styled from "styled-components";

export const head: ITableHeadCell[] = [
  { name: "sellerName", label: "Nome do vendedor", align: "left" },
  { name: "clientName", label: "Nome do cliente", align: "left" },
  { name: "sellerCpforcnpj", label: "Vendedor", align: "left" },
  { name: "clientCpforcnpj", label: "Cliente", align: "left" },
  { name: "value", label: "Valor", align: "left" },
  { name: "nossonumero", label: "Nosso Número", align: "left" },
  { name: "duedate", label: "Vencimento", align: "center" },
  { name: "issuancedate", label: "Emissão", align: "center" },
  { name: "status", label: "Status", align: "center" },
];

export const items: ISelectItem[] = [
  { name: "CPF / CNPJ", value: "clientcpforcnpj", type: "texto" },
  { name: "Status", value: "status", type: "status2" },
  { name: "Data de pagamento", value: "daterange", type: "daterange" },
];

const FinancialBoleto = () => {
  const {
    pageInvoice,
    countInvoice,
    filterInvoice,
    setPageInvoice,
    setFilterInvoice,
    rowsPerPageInvoice,
    setRowsPerPageInvoice,
    invoiceSales,
    salesInfos,
    isLoading,
  } = useDateFilter();

  const { isOpen, onClose, onOpen } = useFinancialFilterHook();

  const removeFilter = (attribute: string) => {
    setFilterInvoice((prevState) => ({
      ...prevState,
      [attribute]: undefined,
    }));
  };

  return (
    <PageContentContainer>
      <TableHeader
        filter={filterInvoice}
        filterBtn={true}
        filterBtnAction={() => onOpen()}
        remove={removeFilter}
        extraComponents={
          <Search
            searchPlaceHolder="Cpf/cnpj do cliente..."
            cpfClient={filterInvoice.clientcpforcnpj}
            querrySearching={invoiceSales.isLoading}
            onChange={(e: string) =>
              setFilterInvoice((prevState) => ({
                ...prevState,
                clientcpforcnpj: e,
              }))
            }
          />
        }
      />

      {!isLoading &&
        !filterInvoice.clientcpforcnpj &&
        !filterInvoice.status && (
          <InfoSection>
            <DateText>
              {`${formatDateBr(filterInvoice.dateStart!)} - ${formatDateBr(
                filterInvoice.dateEnd!
              )}`}
            </DateText>
            <InfosContainer>
              <InfosBox>
                <Typography
                  sx={{ fontWeight: "bold", color: theme.COLORS.GREEN2 }}
                >
                  Pagos
                </Typography>
                <Typography
                  sx={{ fontSize: "1pc", color: theme.COLORS.GREEN2 }}
                >
                  {salesInfos?.boletoPagoCount}
                </Typography>
                <Typography
                  sx={{ fontSize: "1pc", color: theme.COLORS.GREEN2 }}
                >
                  Total: {formatCurrencyBR(salesInfos?.boletoPagoValue)}
                </Typography>
              </InfosBox>
              <InfosBox>
                <Typography
                  sx={{ fontWeight: "bold", color: theme.COLORS.GRAY3 }}
                >
                  Pendentes
                </Typography>
                <Typography sx={{ color: theme.COLORS.GRAY3 }}>
                  {salesInfos?.boletoPendenteCount}
                </Typography>
                <Typography sx={{ fontSize: "1pc", color: theme.COLORS.GRAY3 }}>
                  Total: {formatCurrencyBR(salesInfos?.boletoPendenteValue)}
                </Typography>
              </InfosBox>
              <InfosBox>
                <Typography
                  sx={{ fontWeight: "bold", color: theme.COLORS.YELLOW }}
                >
                  Em pagamento
                </Typography>
                <Typography
                  sx={{ fontSize: "1pc", color: theme.COLORS.YELLOW }}
                >
                  {salesInfos?.boletoEmPagamentoCount}
                </Typography>
                <Typography
                  sx={{ fontSize: "1pc", color: theme.COLORS.YELLOW }}
                >
                  Total: {formatCurrencyBR(salesInfos?.boletoEmPagamentoValue)}
                </Typography>
              </InfosBox>
            </InfosContainer>
          </InfoSection>
        )}

      <ContentBody>
        {invoiceSales.isLoading ? (
          <Box sx={{ position: "relative", height: 500 }}>
            <Spinner
              state={invoiceSales.isLoading}
              size={10}
              css={{
                position: "absolute",
                top: "50%",
                left: "50%",
              }}
            />
          </Box>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <StyledDivDataTable>Cliente</StyledDivDataTable>
                <StyledDivDataTable>Vendedor</StyledDivDataTable>
                <StyledDivDataTable>Valor</StyledDivDataTable>
                <StyledDivDataTable>Nosso Número</StyledDivDataTable>
                <StyledDivDataTable>Datas</StyledDivDataTable>
                <StyledDivDataTable>Status</StyledDivDataTable>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoiceSales.data?.content.map((row: any, index: any) => (
                <TableRow
                  key={row.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    backgroundColor:
                      index % 2 === 0 ? "white" : theme.COLORS.GRAY6,
                  }}
                >
                  <SalesCell component="th" scope="row" size="small">
                    <SalesTypography fontWeight={"bold"}>
                      {row.clientName}
                    </SalesTypography>
                    <SalesTypography>
                      {row.clientCpforcnpj
                        ? formatDocument(row.clientCpforcnpj)
                        : "--"}
                    </SalesTypography>
                  </SalesCell>
                  <SalesCell component="th" scope="row" size="small">
                    <SalesTypography fontWeight={"bold"}>
                      {row.sellerName}
                    </SalesTypography>
                    <SalesTypography>
                      {row.sellerCpforcnpj
                        ? formatDocument(row.sellerCpforcnpj)
                        : "--"}
                    </SalesTypography>
                  </SalesCell>
                  <SalesCell component="th" scope="row" size="small">
                    <SalesTypography>
                      {formatCurrencyBR(row.value)}
                    </SalesTypography>
                  </SalesCell>
                  <SalesCell component="th" scope="row" size="small">
                    <SalesTypography>{row.nossonumero}</SalesTypography>
                  </SalesCell>
                  <SalesCell
                    component="th"
                    scope="row"
                    size="small"
                    width={220}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginRight: "25px",
                        gap: 1,
                      }}
                    >
                      <SalesTypography sx={{ width: "70px" }}>
                        Emissão:
                      </SalesTypography>
                      <SalesTypography fontWeight={"bold"}>
                        {row.issuancedate
                          ? formatarDataSemHoras(row.issuancedate)
                          : "--"}
                      </SalesTypography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginRight: "25px",
                        gap: 1,
                      }}
                    >
                      <SalesTypography sx={{ width: "70px" }}>
                        Vencimento:
                      </SalesTypography>
                      <SalesTypography fontWeight={"bold"}>
                        {row.duedate ? formatarDataSemHoras(row.duedate) : "--"}
                      </SalesTypography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginRight: "25px",
                        gap: 1,
                      }}
                    >
                      <SalesTypography sx={{ width: "70px" }}>
                        Pagamento:
                      </SalesTypography>
                      <SalesTypography fontWeight={"bold"}>
                        {row.paymentdate ? formatarData(row.paymentdate) : "--"}
                      </SalesTypography>
                    </Box>
                  </SalesCell>
                  <SalesCell component="th" scope="row" size="small">
                    <SalesTypography>
                      <StyledStatus status={row.status} />
                    </SalesTypography>
                  </SalesCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
        <DataTablePagination
          setPage={setPageInvoice}
          page={pageInvoice}
          setRowsPerPage={setRowsPerPageInvoice}
          rowsPerPage={rowsPerPageInvoice}
          count={countInvoice}
        />
      </ContentBody>

      <DefaultFilter
        isOpen={isOpen}
        items={items}
        onChangeFilter={setFilterInvoice}
        onClose={onClose}
        onOpen={onOpen}
        title="Filtrar transações"
      />
    </PageContentContainer>
  );
};

export default FinancialBoleto;

const InfoSection = styled(Box)`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const DateText = styled(Typography)`
  color: ${theme.COLORS.BLUE3} !important;
  font-size: 1.1pc !important;
  font-weight: bold !important;
  position: absolute !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  top: 20px !important;
`;

const InfosContainer = styled(Box)`
  background-color: #fff;
  display: flex;
  flex-direction: row;
`;

const InfosBox = styled(Box)`
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 0 1%;
`;
