import { Stepper } from "@mui/material";
import styled from "styled-components";

import { CustomStepLabel } from "./setpLabel";
import { StepConfig } from "./newSale";
import { useSaleFormContext } from "core/context/SaleContext";

/*
    Stepper, aqui é recebido um array com os itens que serão os paços
    Também será recebido o passo que o usuário está atualmente
    Os icones e cores responderão de acordo com essas duas informações
*/

interface Step {
  stepsConfig: StepConfig[];
}

export default function CustomStepper({ stepsConfig }: Readonly<Step>) {
  const { activeStep } = useSaleFormContext();

  return (
    <StyledStepper alternativeLabel connector={null} activeStep={activeStep}>
      {stepsConfig.map((step, index) => (
        <CustomStepLabel
          key={step.label}
          index={index}
          stepsConfig={stepsConfig}
          active={index === activeStep}
          completed={index < activeStep}
          icon={index + 1}
        />
      ))}
    </StyledStepper>
  );
}

const StyledStepper = styled(Stepper)`
  display: flex;
  padding: 0;
  position: relative;
  justify-content: space-between;
  background: ${({ theme }) => theme.COLORS.GRAY6};
  border-bottom: 4px solid ${({ theme }) => theme.COLORS.BLACK};

  // Scrollbar Styles
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }
`;
